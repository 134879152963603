import React from "react";
// translation hook
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
// redux
import { useDispatch, useSelector } from "react-redux";
import { userUpdatePassword, removeToken } from "../../store/slices/auth";
import { setSuccess } from "../../store/slices/common";
// components
import {
  SigninFooter,
  SigninHeader,
  UpdatePasswordForm
} from "../../components";
// custom styles
import "./styles.less";
// constants
import { userTypes } from "../../util/Enums";

const UpdatePassword = ({ needUpdate }) => {
  const dispatch = useDispatch();
  const {
    common: { loading, success },
    auth: {
      user: { email, documentNumber },
      userType
    }
  } = useSelector((state) => state);
  const { t: translation } = useTranslation();

  const onSubmit = (values) => {
    const requestData = {
      values,
      email,
      documentNumber
    };
    dispatch(userUpdatePassword(requestData));
  };

  const onConfirm = () => {
    dispatch(setSuccess(false));
    dispatch(removeToken());
  };

  return (
    <>
      {needUpdate ? (
        <div className="gx-login-container">
          <div className="updatePasswordPage">
            <h4 className="pageTitle">
              {translation("auth.updatePassword.headerTitle")} <br />
              {translation("auth.updatePassword.headerSubtitle")}
            </h4>
            <div
              className={`gx-login-content updatePasswordContent ${
                userType === userTypes.BROKER ? "withFooter" : ""
              }`}
            >
              <SigninHeader
                type={userType}
                title={translation("auth.updatePassword.formTitle")}
              />
              <UpdatePasswordForm
                loading={loading}
                onSubmit={onSubmit}
                placeholder={translation(
                  "auth.updatePassword.oldPasswordPlaceholder"
                )}
              />
              {userType === userTypes.BROKER ? (
                <SigninFooter className="gx-mt-2" />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="updatePasswordSection">
          <h4 className="sectionTitle">
            {translation("auth.updatePassword.sectionTitle")}
          </h4>
          <div className="gx-login-content updatePasswordContent">
            <h3 className="contentTitle">
              {translation("auth.updatePassword.formTitle")}
            </h3>
            <UpdatePasswordForm
              loading={loading}
              onSubmit={onSubmit}
              placeholder={translation(
                "auth.updatePassword.currentPasswordPlaceholder"
              )}
            />
          </div>
        </div>
      )}

      <SweetAlert
        show={success}
        success
        title={translation("auth.updatePassword.sweetAlertSuccessTitle")}
        onConfirm={onConfirm}
        confirmBtnText={translation(
          "auth.updatePassword.sweetAlertSuccessButton"
        )}
      >
        {translation("auth.updatePassword.sweetAlertSuccessMessage")}
      </SweetAlert>
    </>
  );
};

export default UpdatePassword;
