import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import "./styles.less";
// translation
import { useTranslation } from "react-i18next";

const NewTable = ({
  requestData,
  requestAction,
  className,
  headerItems,
  onPaginationChange,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const addIndexToDataSource = () => {
    if (requestData) {
      return requestData.data.map((item, index) => {
        return {
          ...item,
          key: index
        };
      });
    }
    return [];
  };

  const pagination = {
    current: requestData.pageNumber + 1,
    pageSize: requestData.pageSize,
    total: requestData.total,
    onChange: (page, pageSize) =>
      onPaginationChange && onPaginationChange(page, pageSize)
  };

  useEffect(() => {
    dispatch(requestAction());
  }, [dispatch, requestAction]);

  return (
    <div className="newTableStyle">
      {headerItems}
      <Table
        className={`${className} gx-table-responsive gx-w-100`}
        tableLayout="fixed"
        dataSource={addIndexToDataSource()}
        pagination={pagination}
        locale={{ emptyText: translation("components.antTable.emptyData") }}
        {...props}
      />
    </div>
  );
};

export default NewTable;
