import React, { Fragment } from "react";
// router
import { Switch, Route, Redirect } from "react-router-dom";
// components
import UpdatePassword from "../pages/UpdatePassword";
import Company from "../pages/Company";
import SignIn from "../pages/SignIn";
import Dashboard from "../pages/dashboard";
import PolicyList from "../pages/Policy/PolicyList";
import PolicyItem from "../pages/Policy/PolicyItem";
import ForgotPassword from "../pages/ForgotPassword";
import Report from "../pages/Register/Report";
import Register from "../pages/Register";
import RegisterManually from "../pages/Register/RegisterManually";
import Profile from "../pages/Profile";
import Users from "../pages/Users";
import CreateUser from "../pages/Users/CreateUser";
import RegisterConfirm from "../pages/Register/RegisterConfirm";
import RegisterProtocol from "../pages/Register/RegisterProtocol";
import RegisterCancel from "../pages/Register/RegisterCancel";
import ReportDetails from "../pages/Register/ReportDetails";
import BrokerHome from "../pages/BrokerHome";
import Cancel from "../pages/Register/Cancel";
import Sinister from "../pages/Sinister";
import CancelManually from "../pages/Register/Cancel/CancelManually";
import CancelConfirm from "../pages/Register/Cancel/CancelConfirm";
import CancelProtocol from "../pages/Register/Cancel/CancelProtocol";

// translation
import i18n from "../translation/i18n";

// paths
import {
  PATH_HOME,
  PATH_AUTH,
  PATH_USERS,
  PATH_POLICY,
  PATH_REGISTER,
  PATH_COMPANY,
  PATH_PROFILE,
  PATH_REPORT,
  PATH_ROOTS,
  PATH_CANCEL,
  PATH_SINISTER
} from "./paths";
import { userTypes } from "../util/Enums";

import UserStatusGuard from "../guards/UserStatusGuard";
import AuthGuard from "../guards/AuthGuard";

import WithSider from "../layouts/WithSider";

const renderRoutes = (routes = [], userType) => {
  const getPath = (isPublic, path) => {
    return isPublic ? path : `${PATH_ROOTS[userType]}${path}`;
  };

  return (
    <Switch>
      {routes.map((route, index) => {
        const Component = route.component || Fragment;
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        if (userType !== userTypes.BROKER && route.path === PATH_HOME.broker) {
          return null;
        }
        if (route.path === "*") {
          return <Redirect to={getPath(route.isPublic, PATH_HOME.root)} />;
        }
        return (
          <Route
            key={index}
            exact={route.exact}
            path={route.path && getPath(route.isPublic, route.path)}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes, userType)
                  ) : (
                    <Component
                      title={route.title}
                      previous={route.previous}
                      {...props}
                    />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  );
};

export const routes = [
  // customer ----------------------------
  {
    exact: true,
    path: PATH_AUTH.login,
    component: SignIn,
    isPublic: true
  },
  {
    exact: true,
    path: PATH_AUTH.forgotPassword,
    component: ForgotPassword,
    isPublic: true
  },
  // broker -----------------------------
  {
    exact: true,
    path: `${PATH_ROOTS.BROKER}${PATH_AUTH.login}`,
    component: SignIn,
    isPublic: true
  },
  {
    exact: true,
    path: `${PATH_ROOTS.BROKER}${PATH_AUTH.forgotPassword}`,
    component: ForgotPassword,
    isPublic: true
  },

  // private -----------------------------------
  {
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: PATH_AUTH.updatePassword,
        component: () => <UpdatePassword needUpdate />,
        title: i18n.t("auth.updatePassword.headerTitle"),
        previous: PATH_HOME.root
      },
      {
        exact: true,
        path: `${PATH_ROOTS.BROKER}${PATH_AUTH.updatePassword}`,
        component: () => <UpdatePassword needUpdate />
      },
      {
        guard: UserStatusGuard,
        layout: WithSider,
        routes: [
          // home----------------------------
          {
            exact: true,
            path: PATH_HOME.root,
            component: Dashboard,
            title: ""
          },
          {
            exact: true,
            path: PATH_HOME.broker,
            component: BrokerHome,
            title: "",
            previous: null
          },
          // auth----------------------------
          {
            exact: true,
            path: PATH_AUTH.changePassword,
            component: UpdatePassword,
            title: i18n.t("auth.updatePassword.headerTitle"),
            previous: PATH_HOME.root
          },
          // policy--------------------------
          {
            exact: true,
            path: PATH_POLICY.root,
            component: PolicyList,
            title: i18n.t("policy.list.pageTitle"),
            previous: PATH_HOME.root
          },
          {
            exact: true,
            path: PATH_POLICY.policyItem,
            component: PolicyItem,
            title: i18n.t("policy.pageTitle")
          },
          // register------------------------
          {
            exact: true,
            path: PATH_REGISTER.root,
            component: Register,
            title: i18n.t("register.pageTitle"),
            previous: PATH_HOME.root
          },
          // {
          //   exact: true,
          //   path: PATH_REGISTER.others,
          //   component: (props) => {
          //     return (
          //       <Register {...props}>
          //         <RegisterManually />
          //       </Register>
          //     );
          //   },
          //   title: i18n.t("register.manually.pageTitle"),
          //   pageTag: {
          //     type: "error",
          //     label: i18n.t("register.pendingTagLabel")
          //   }
          // },
          {
            exact: true,
            path: PATH_REGISTER.confirm,
            component: (props) => {
              return (
                <Register {...props}>
                  <RegisterConfirm />
                </Register>
              );
            },
            title: i18n.t("register.confirm.pageTitle"),
            pageTag: {
              type: "error",
              label: i18n.t("register.pendingTagLabel")
            }
          },
          {
            exact: true,
            path: PATH_REGISTER.protocol,
            component: (props) => {
              return (
                <Register {...props}>
                  <RegisterProtocol />
                </Register>
              );
            },
            title: i18n.t("register.confirm.pageTitle"),
            pageTag: {
              type: "success",
              label: i18n.t("register.completedTagLabel")
            }
          },
          // report--------------------------
          {
            exact: true,
            path: PATH_REPORT.root,
            component: Report,
            title: i18n.t("register.reportTitle"),
            previous: PATH_HOME.root
          },
          {
            exact: true,
            path: PATH_REPORT.details,
            component: ReportDetails,
            title: i18n.t("report.details.pageTitle")
          },
          {
            exact: true,
            path: PATH_REPORT.cancel,
            component: RegisterCancel,
            title: i18n.t("cancel.pageTitle")
          },
          {
            exact: true,
            path: PATH_REPORT.confirm,
            component: (props) => {
              return (
                <Report {...props}>
                  <RegisterConfirm />
                </Report>
              );
            },
            title: i18n.t("register.confirm.pageTitle"),
            pageTag: {
              type: "error",
              label: i18n.t("register.pendingTagLabel")
            }
          },
          // company--------------------------
          {
            exact: true,
            path: PATH_COMPANY.root,
            component: Company,
            title: i18n.t("company.sectionHeader"),
            previous: PATH_HOME.root
          },
          // profile--------------------------
          {
            exact: true,
            path: PATH_PROFILE.root,
            component: Profile,
            title: i18n.t("profile.pageTitle"),
            previous: PATH_HOME.root
          },
          // users---------------------------
          {
            exact: true,
            path: PATH_USERS.root,
            component: Users,
            title: i18n.t("users.userListTitle"),
            previous: PATH_HOME.root
          },
          {
            exact: true,
            path: PATH_USERS.newUser,
            component: CreateUser,
            title: i18n.t("createUser.pageTitle")
          },
          {
            exact: true,
            path: PATH_USERS.editUser,
            component: CreateUser,
            title: i18n.t("createUser.pageTitle")
          },
          // cancelar----------------------------
          {
            exact: true,
            path: PATH_REGISTER.cancel,
            component: RegisterCancel,
            title: i18n.t("cancel.pageTitle"),
            previous: PATH_HOME.root
          },
          {
            exact: true,
            path: PATH_CANCEL.root,
            component: Cancel
          },
          // {
          //   exact: true,
          //   path: PATH_CANCEL.manually,
          //   component: CancelManually
          // },
          {
            exact: true,
            path: PATH_CANCEL.confirm,
            component: (props) => (
              <Cancel {...props}>
                <CancelConfirm />
              </Cancel>
            ),
            previous: PATH_CANCEL.root
          },
          {
            exact: true,
            path: PATH_CANCEL.protocol,
            component: (props) => (
              <Cancel {...props}>
                <CancelProtocol />
              </Cancel>
            ),
            previous: PATH_CANCEL.root
          },
          // sinister----------------------------
          {
            exact: true,
            path: PATH_SINISTER.root,
            component: Sinister,
            title: i18n.t("sinister.pageTitle"),
            previous: PATH_HOME.root
          },
          // * ----------------------------------
          {
            exact: true,
            path: "*"
          }
        ]
      }
    ]
  }
];

export default renderRoutes;
