import React, { useState, useEffect } from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// router
import { useParams } from "react-router-dom";
// antd
import { Row, Spin } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRegisterById,
  cancelRegisterManually,
  setErrors
} from "../../../store/slices/sections/register";
// components
import {
  ReportViewer,
  ActionButton,
  PageHeader,
  CancelReasonModal
} from "../../../components";
// custom hook
import { useRedirect } from "../../../hooks";
// paths
import { PATH_REPORT, PATH_CANCEL } from "../../../routes/paths";
// util
import { getErrorMessage } from "../../../util/Errors";
import { registerStatusEnum } from "../../../util/Enums";

const ReportDetails = ({ title }) => {
  const [showModal, setShowModal] = useState(false);

  const {
    register: { registerData, errors },
    common: { loading }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { t: translation } = useTranslation();
  const { redirect } = useRedirect();
  const { id } = useParams();

  const getTagLabel = () => {
    if (registerData.status === registerStatusEnum.cancelled) {
      return {
        type: "error",
        label: translation("cancel.cancelledTag")
      };
    }

    if (registerData.cancelRefuseReason) {
      return {
        type: "error",
        label: translation("cancel.manually.cancelRefusedTagLabel")
      };
    }

    return null;
  };

  const handleRedirect = () => {
    if (registerData && registerData.documentType === "OTHERS") {
      // return setShowModal(true);
      return null;
    }

    return redirect(`${PATH_CANCEL.root}`);
  };

  const handleCancelManually = (cancelReason) => {
    dispatch(cancelRegisterManually(id, cancelReason)).then(() => {
      setShowModal(false);
      return redirect(`${PATH_CANCEL.root}/outros/${id}`);
    });
  };

  const handleChange = () => {
    dispatch(setErrors([]));
  };

  const handleCloseModal = () => {
    dispatch(setErrors([]));
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(fetchRegisterById(id)).catch(() => redirect(PATH_REPORT.root));
  }, [dispatch]);

  return (
    <Spin spinning={loading}>
      <PageHeader title={title} pageTag={getTagLabel()} />
      <ReportViewer reportData={registerData} />
      {!(registerData.status === registerStatusEnum.cancelled) && (
        <Row justify="center" className="gx-mt-3">
          <ActionButton type="primary" size="large" onClick={handleRedirect}>
            {translation("report.details.cancelButton")}
          </ActionButton>
        </Row>
      )}
      <CancelReasonModal
        visible={showModal}
        loading={loading}
        title={translation("cancel.modal.title")}
        help={getErrorMessage("cancelReason", errors)}
        buttonText={translation("cancel.modal.button")}
        onCancel={handleCloseModal}
        onChange={handleChange}
        onSubmit={(cancelReason) => handleCancelManually(cancelReason)}
      />
    </Spin>
  );
};

ReportDetails.propTypes = {
  title: PropTypes.string
};

export default ReportDetails;
