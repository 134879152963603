import React, { useState } from "react";
import { AutoComplete } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { fetchLocation } from "../../store/slices/common";
import "./styles.less";

const { Option } = AutoComplete;

const LocationInput = ({
  placeholder,
  onChange,
  value = "",
  onSearchError,
  ...restProps
}) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const onSearch = (searchText) => {
    if (searchText.length > 3) {
      setLoading(true);
      dispatch(fetchLocation(searchText)).then((response) => {
        setLoading(false);
        setOptions(response);
        if (response.length === 0) {
          onSearchError();
        }
      });
    } else {
      setOptions([]);
    }
  };

  const handleChange = (value) => {
    setFieldValue(value);
    triggerChange(value);
  };

  const onSelect = (value) => {
    const selected = options.filter((item) => {
      return item.id == value;
    });
    setFieldValue(selected[0].description);
  };

  return (
    <>
      <AutoComplete
        value={fieldValue}
        style={{
          width: "100%"
        }}
        onSearch={onSearch}
        onChange={handleChange}
        onSelect={onSelect}
        className="autoComplete-field"
        placeholder={placeholder}
        {...restProps}
      >
        {options &&
          options.map((item) => {
            return (
              <Option key={item.id} value={`${item.id}`}>
                {item.description}
              </Option>
            );
          })}
      </AutoComplete>
      {loading && <LoadingOutlined spin className="loading-location-icon" />}
    </>
  );
};

export default LocationInput;
