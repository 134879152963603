import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RegisterViewer, ActionButton } from "../../../components";
import {
  setCurrentStep,
  fetchRegisterById,
  confirmRegister,
  updateStepper,
  setRegisterData
} from "../../../store/slices/sections/register";
import "./styles.less";
import { PATH_REGISTER } from "../../../routes/paths";
import { useRedirect } from "../../../hooks";
// enums
import { registerStatusEnum, policyTypes } from "../../../util/Enums";
// options
import { registerSteps } from "../../../util/Options";

const RegisterConfirm = () => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const { id } = useParams();
  const {
    register: { registerData },
    common: { fetching, loading }
  } = useSelector((state) => state);
  const { redirect } = useRedirect();

  const handleConfirmRegister = () => {
    dispatch(confirmRegister(id)).then((response) => {
      dispatch(setRegisterData(response.data));
      redirect(`${PATH_REGISTER.root}/${id}/protocolo`);
    });
  };

  const getPolicyTypeLabel = () => {
    let policyTypeLabel = "";
    if (registerData.protocols) {
      for (let index = 0; index < registerData.protocols.length; index += 1) {
        policyTypeLabel +=
          index === registerData.protocols.length - 1
            ? ` e ${policyTypes[registerData.protocols[index].type]}`
            : `, ${policyTypes[registerData.protocols[index].type]}`;
      }
    }
    return policyTypeLabel;
  };

  useEffect(() => {
    dispatch(updateStepper(registerSteps.register));
    dispatch(setCurrentStep(1));
    const fetchRegisterAndRedirect = () => {
      dispatch(fetchRegisterById(id)).catch(() => redirect(PATH_REGISTER.root));
    };
    fetchRegisterAndRedirect();
  }, [dispatch]);

  return (
    registerData &&
    !fetching && (
      <div className="gx-pt-5">
        <RegisterViewer registerData={registerData} />
        {!(registerData.status === registerStatusEnum.cancelled) ? (
          <div className="confirm-button-area">
            <p>
              {translation("register.confirmPageLabel")}
              {getPolicyTypeLabel()}
            </p>
            <ActionButton
              onClick={handleConfirmRegister}
              type="primary"
              size="large"
              loading={loading}
            >
              {translation("register.confirmPageButtonLabel")}
            </ActionButton>
          </div>
        ) : null}
      </div>
    )
  );
};

export default RegisterConfirm;
