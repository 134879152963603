import React from "react";
import { Layout, Breadcrumb } from "antd";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { COMPANY } from "../../util/Masks";
import { userTypes } from "../../util/Enums";
import "./styles.less";

const { Header } = Layout;

const BreadCrumbView = () => {
  const { pathname } = useLocation();
  const paths = pathname.split("/").filter((item) => item);
  return (
    <Breadcrumb separator=">">
      {paths.map((name, index) => {
        const routeTo = `/${paths.slice(0, index + 1).join("/")}`;
        const isLast = index === paths.length - 1;

        return isLast ? (
          <Breadcrumb.Item key={index} className="gx-text-capitalize">
            {name}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index} className="gx-text-capitalize">
            <Link to={`${routeTo}`} style={{ color: "#038fde" }}>
              {name}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
const NavBar = () => {
  const {
    auth: { user, userType },
    company: { companyData }
  } = useSelector((state) => state);
  const { t: Translation } = useTranslation();

  return (
    <Header>
      {userType === userTypes.BROKER ? (
        user.insuredDocumentNumber && (
          <div className="navBarInsurerInfos">
            <span className="navBarInsurerLabel">
              {Translation("navbar.insurerLabel")}
            </span>
            <span className="navBarInsurerCompany">
              {companyData.name} - CNPJ{" "}
              {
                <NumberFormat
                  displayType="text"
                  value={companyData.documentNumber}
                  format={COMPANY}
                />
              }
            </span>
          </div>
        )
      ) : (
        <>
          {/* <BreadCrumbView /> */}
          <div className="navBarInsurerInfos">
            <span className="navBarInsurerCompany">
              {companyData.name} - CNPJ{" "}
              {
                <NumberFormat
                  displayType="text"
                  value={companyData.documentNumber}
                  format={COMPANY}
                />
              }
            </span>
          </div>
        </>
      )}
    </Header>
  );
};
export default NavBar;
