import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locale/en/translation.json";
import pt from "./locale/pt/translation.json";
import es from "./locale/es/translation.json";

// the translations
const resources = {
  en,
  pt,
  es
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",

  keySeparator: true,

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
