import React from "react";
// translation hook
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
// antd
import { Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
// redux
import { useDispatch, useSelector } from "react-redux";
import { userForgotPassword } from "../../store/slices/auth";
import { setSuccess } from "../../store/slices/common";
// custom hook
import { useRedirect } from "../../hooks";
// paths
import { PATH_AUTH } from "../../routes/paths";
// mask
import { CNPJ_MASK_STRING } from "../../util/Masks";
// components
import {
  ActionButton,
  SigninHeader,
  LabelField,
  FormMaskedInput,
  FormWrapper,
  FormInput,
  SigninFooter
} from "../../components";
// custom styles
import "./styles.less";
// constants
import { userTypes } from "../../util/Enums";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const {
    common: { loading, success },
    auth: { userType, errors }
  } = useSelector((state) => state);
  const { redirect } = useRedirect();
  const { t: translation } = useTranslation();

  const onSubmit = (values) => {
    const data = {
      ...values,
      documentNumber: values.documentNumber.replace(/[^A-Z0-9]/gi, "")
    };
    dispatch(userForgotPassword(data));
  };

  const onConfirm = () => {
    dispatch(setSuccess(false));
    redirect(PATH_AUTH.login);
  };

  return (
    <div className="gx-login-container">
      <div className="forgotPasswordPage">
        <h4 className="pageTitle">
          {translation("auth.forgotPassword.pageTitle")}
        </h4>
        <div
          className={`gx-login-content forgotPasswordContent ${
            userType === userTypes.BROKER ? "withFooter" : ""
          }`}
        >
          <SigninHeader
            type={userType}
            title={translation("auth.forgotPassword.title")}
          />
          <FormWrapper spinning={false} onSubmit={onSubmit} errors={errors}>
            <LabelField
              label={translation("auth.signIn.documentNumberLabel")}
              vertical
              className="gx-mb-2"
            >
              <FormMaskedInput
                name="documentNumber"
                mask={CNPJ_MASK_STRING}
                placeholder={translation(
                  "auth.forgotPassword.documentNumberPlaceholder"
                )}
                prefix={<UserOutlined />}
                rules={[
                  {
                    required: true,
                    message: `${translation(
                      "auth.forgotPassword.documentNumberRequiredMessage"
                    )}`
                  }
                ]}
              />
            </LabelField>
            <LabelField
              label={translation("auth.signIn.emailLabel")}
              vertical
              className="gx-mb-2"
            >
              <FormInput
                name="email"
                placeholder={translation(
                  "auth.forgotPassword.emailPlaceholder"
                )}
                prefix={<UserOutlined />}
                rules={[
                  {
                    required: true,
                    message: `${translation(
                      "auth.forgotPassword.emailRequiredMessage"
                    )}`
                  }
                ]}
              />
            </LabelField>
            <Row justify="center" align="middle" className="gx-mt-3">
              <ActionButton
                ghost
                type="primary"
                className="gx-mb-0"
                onClick={() => redirect(PATH_AUTH.login)}
              >
                {translation("auth.forgotPassword.backButton")}
              </ActionButton>
              <ActionButton
                loading={loading}
                type="primary"
                htmlType="submit"
                className="gx-mb-0"
              >
                {translation("auth.forgotPassword.sendButton")}
              </ActionButton>
            </Row>
          </FormWrapper>
          {userType === userTypes.BROKER ? (
            <SigninFooter className="gx-mt-2" />
          ) : null}
        </div>
      </div>
      <SweetAlert
        show={success}
        success
        title={translation("auth.forgotPassword.sweetAlertSuccessTitle")}
        onConfirm={onConfirm}
        confirmBtnText={translation(
          "auth.forgotPassword.sweetAlertSuccessButton"
        )}
      >
        {translation("auth.forgotPassword.sweetAlertSuccessMessage")}
      </SweetAlert>
    </div>
  );
};

export default ForgotPassword;
