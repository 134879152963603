import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Col, Row } from "antd";
// translation hook
import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData, saveUserData } from "../../store/slices/auth";
// components
import {
  FormWrapper,
  FormInput,
  ActionButton,
  LabelField,
  FormMaskedInput,
  PageHeader
} from "../../components";
// mask
import { COMPANY, PHONEMASK_NUMBER } from "../../util/Masks";
// formatters
import { removeMask } from "../../util/formatters";
import { userTypes } from "../../util/Enums";

const Profile = ({ title, previous }) => {
  const [changed, setChanged] = useState(false);

  const {
    auth: { user, userType, errors },
    common: { loading }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const labelSize = 113;
  const onValuesChanged = () => {
    setChanged(true);
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      phoneNumber: removeMask(values.phoneNumber),
      documentNumber:
        userType === userTypes.BROKER
          ? removeMask(user.insuredDocumentNumber)
          : removeMask(user.documentNumber)
    };
    dispatch(saveUserData(data));
  };

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  return (
    <>
      <PageHeader title={title} previous={previous} />
      <FormWrapper
        title={translation("profile.pageTitle")}
        onSubmit={onSubmit}
        onValuesChange={onValuesChanged}
        dataReference={user}
        errors={errors}
      >
        <Row align="middle" className="gx-mb-1">
          <Col md={12} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("profile.documentNumberLabel")}
            >
              <NumberFormat
                displayType="text"
                value={
                  userType === userTypes.BROKER
                    ? user.insuredDocumentNumber
                    : user.documentNumber
                }
                format={COMPANY}
              />
            </LabelField>
          </Col>
        </Row>
        <Row align="middle">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("profile.nameLabel")}
            >
              <FormInput
                name="name"
                placeholder={translation("profile.namePlaceholder")}
              />
            </LabelField>
          </Col>
        </Row>
        <Row align="middle">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("profile.emailLabel")}
            >
              <FormInput
                name="email"
                placeholder={translation("profile.emailPlaceholder")}
              />
            </LabelField>
          </Col>
        </Row>
        <Row align="middle">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("profile.phoneNumberLabel")}
            >
              <FormMaskedInput
                name="phoneNumber"
                placeholder={translation("profile.phoneNumberPlaceholder")}
                mask={PHONEMASK_NUMBER}
              />
            </LabelField>
          </Col>
        </Row>
        <Row align="middle" className="gx-mb-1">
          <Col md={12} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("profile.statusLabel")}
            >
              {user.status}
            </LabelField>
          </Col>
        </Row>
        <Row align="middle" className="gx-mb-3">
          {/* //TODO: deverá removido eventualmente quando sejam definidas as permissões dos usuários */}
          <Col md={12} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("profile.permissionsLabel")}
            >
              Administrador
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="center" className="gx-px-3 ">
          <ActionButton
            size="middle"
            className="gx-mb-0"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {translation("profile.saveButton")}
          </ActionButton>
        </Row>
      </FormWrapper>
    </>
  );
};

export default Profile;
