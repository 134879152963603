import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { api } from "../../../../util/Api";
import { setLoading, setFetching } from "../../common";
import i18n from "../../../../translation/i18n";
import { userStatus } from "../../../../util/Enums";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    errors: [],
    userList: {
      data: []
    },
    selectedUser: {},
    userSaved: false
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload || [];
    },
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    setUser: (state, action) => {
      state.selectedUser = {
        ...state.selectedUser,
        ...action.payload
      };
    },
    clearUser: (state, action) => {
      state.selectedUser = {};
    },
    setUserSaved: (state, action) => {
      state.userSaved = action.payload;
    }
  }
});

export const { setErrors, setUserList, setUser, clearUser, setUserSaved } =
  usersSlice.actions;

export const createUser = (data) => (dispatch) => {
  dispatch(setLoading(true));
  api
    .post("/auth/v1/user", data)
    .then((response) => {
      dispatch(setUserSaved(true));
      dispatch(setLoading(false));
      message.success(i18n.t("createUser.saveUserDataSuccess"));
    })
    .catch((error) => {
      dispatch(setUserSaved(false));
      dispatch(setLoading(false));
      if (
        error.response.data.errors &&
        Array.isArray(error.response.data.errors)
      ) {
        dispatch(setErrors(error.response.data.errors));
      }
      message.error(error.response.data.message);
    });
};

export const updateUser = (data) => (dispatch) => {
  const { documentNumber, email } = data;
  dispatch(setLoading(true));
  api
    .put(`/auth/v1/user/${email}`, data)
    .then((response) => {
      dispatch(setUserSaved(true));
      dispatch(setLoading(false));
      message.success(i18n.t("updateUser.updateUserDataSuccess"));
    })
    .catch((error) => {
      dispatch(setUserSaved(false));
      dispatch(setLoading(false));
      if (
        error.response.data.errors &&
        Array.isArray(error.response.data.errors)
      ) {
        dispatch(setErrors(error.response.data.errors));
      }
      message.error(error.response.data.message);
    });
};

export const fetchUserList = () => (dispatch) => {
  dispatch(setFetching(true));
  api
    .get("/auth/v1/user")
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(setUserList(response.data));
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export const blockUser = (item) => (dispatch) => {
  dispatch(setLoading(true));
  const { email, documentNumber } = item;
  return api
    .put(`/auth/v1/user/${email}/block`)
    .then(() => {
      dispatch(setLoading(false));
      dispatch(fetchUserList());
      dispatch(setUser({ status: userStatus.BLOCKED }));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      message.error(error.message);
    });
};

export const unBlockUser = (item) => (dispatch) => {
  dispatch(setLoading(true));
  const { email, documentNumber } = item;
  return api
    .put(`/auth/v1/user/${email}/unblock`)
    .then(() => {
      dispatch(setLoading(false));
      dispatch(fetchUserList());
      dispatch(setUser({ status: userStatus.OK }));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      message.error(error.message);
    });
};

export const deleteUser = (item) => (dispatch) => {
  // TODO - Descomentar assim que houver api pra testar
  dispatch(setLoading(true));
  const { email } = item;
  return api
    .delete(`/auth/v1/user/${email}`)
    .then(() => {
      dispatch(setLoading(false));
      message.success(i18n.t("users.deletedUserSuccess"));
      dispatch(fetchUserList());
    })
    .catch((error) => {
      dispatch(setLoading(false));
      message.error(error.message);
    });
};

export const blockManyUsers = (list) => (dispatch) => {
  dispatch(setLoading(true));
  Promise.all(
    list.map((item) => {
      return dispatch(blockUser(item));
    })
  ).then(() => {
    if (list.length > 1) {
      message.success(i18n.t("users.blockedUsersSuccess"));
    } else {
      message.success(i18n.t("users.blockedUserSuccess"));
    }
  });
};

export const unBlockManyUsers = (list) => (dispatch) => {
  dispatch(setLoading(true));
  Promise.all(
    list.map((item) => {
      return dispatch(unBlockUser(item));
    })
  ).then(() => {
    if (list.length > 1) {
      message.success(i18n.t("users.unBlockedUsersSuccess"));
    } else {
      message.success(i18n.t("users.unBlockedUserSuccess"));
    }
  });
};

export const requestNewPassword = (data) => (dispatch) => {
  const { email, documentNumber } = data;
  dispatch(setLoading(true));
  api
    .post(
      `/auth/v1/authenticate/${documentNumber}/${email}/forgot-password`,
      data
    )
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setErrors(error.response.data.errors));
      if (!Array.isArray(error.response.data.errors)) {
        message.error(error.response.data.message);
      }
    });
};

export const sendPassword = (list) => (dispatch) => {
  dispatch(setLoading(true));
  Promise.all(
    list.map((item) => {
      const { email, documentNumber, type } = item;
      return dispatch(
        requestNewPassword({ email, documentNumber, userType: type })
      );
    })
  ).then(() => {
    if (list.length > 1) {
      message.success(i18n.t("users.sendNewPasswordsSuccess"));
    } else {
      message.success(i18n.t("users.sendNewPasswordSuccess"));
    }
    return dispatch(fetchUserList());
  });
};

export const fetchSelectedUser = (data) => (dispatch) => {
  const { documentNumber, email } = data;
  dispatch(setFetching(true));
  api
    .get(`/auth/v1/user/${email}`)
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(setUser(response.data));
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export default usersSlice.reducer;
