import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { message } from "antd";

import i18n from "../../../../translation/i18n";
import { ISODATE } from "../../../../util/Masks";
import { api } from "../../../../util/Api";
import { datePeriods } from "../../../../constants";
import { registerRangeEnum, registerStatusEnum } from "../../../../util/Enums";

import { setFetching, setLoading } from "../../common";
import { documentTypes, registerSteps } from "../../../../util/Options";

export const registerSlice = createSlice({
  name: "register",
  initialState: {
    registerData: {
      documentType: documentTypes[0].value
    },
    currentStep: 0,
    rangeParam: registerRangeEnum.today,
    reportFilters: {
      startAt: moment().format(ISODATE),
      endAt: moment().format(ISODATE),
      status: registerStatusEnum.all
    },
    reportList: {
      data: []
    },
    errors: [],
    steps: registerSteps.register
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setRegisterRangeParam: (state, action) => {
      state.rangeParam = action.payload;
    },
    setRegisterStatusParam: (state, action) => {
      state.reportFilters.status = action.payload;
    },
    setReportFilters: (state, action) => {
      state.reportFilters.startAt = action.payload.startAt;
      state.reportFilters.endAt = action.payload.endAt;
    },
    setReportList: (state, action) => {
      state.reportList = action.payload;
    },
    setRegisterData: (state, action) => {
      state.registerData = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload || [];
    },
    cleanRegisterData: (state) => {
      state.registerData = {
        documentType: documentTypes[0].value
      };
    },
    updateStepper: (state, action) => {
      state.steps = action.payload;
    },
    setChangedRegisterData: (state, action) => {
      state.registerData = {
        ...state.registerData,
        ...action.payload
      };
    },
    appendFilters: (state, action) => {
      state.reportFilters = {
        ...state.reportFilters,
        ...action.payload
      };
    }
  }
});

export const {
  setRegisterRangeParam,
  setRegisterStatusParam,
  setReportFilters,
  setReportList,
  setCurrentStep,
  setRegisterData,
  setErrors,
  cleanRegisterData,
  updateStepper,
  setChangedRegisterData,
  appendFilters
} = registerSlice.actions;

// TODO: Encontrar uma forma de reduzir esse código
export const generateRangeValue = (rangeType) => (dispatch) => {
  dispatch(setRegisterRangeParam(rangeType));
  switch (rangeType) {
    case registerRangeEnum.today:
      dispatch(setReportFilters(datePeriods.today));
      break;
    case registerRangeEnum.month:
      dispatch(setReportFilters(datePeriods.month));
      break;
    case registerRangeEnum.lastSevenDays:
      dispatch(setReportFilters(datePeriods.lastSevenDays));
      break;
    case registerRangeEnum.lastThirtyDays:
      dispatch(setReportFilters(datePeriods.lastThirtyDays));
      break;
    default:
      dispatch(setReportFilters({ startAt: null, endAt: null }));
      break;
  }
};

export const fetchRegisterReportList = () => (dispatch, getState) => {
  const {
    register: { reportFilters }
  } = getState();
  const queryString = new URLSearchParams({ ...reportFilters }).toString();
  dispatch(setFetching(true));
  api
    .get(`averbamais-engine/v1/register?${queryString}`)
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(setReportList(response.data));
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export const fetchRegisterById = (id) => (dispatch) => {
  dispatch(setFetching(true));
  return new Promise((resolve, reject) => {
    return api
      .get(`averbamais-engine/v1/register/${id}`)
      .then((response) => {
        dispatch(setRegisterData(response.data));
        dispatch(setFetching(false));
        resolve();
      })
      .catch((error) => {
        message.error(i18n.t("register.requestByIdError"));
        dispatch(setFetching(false));
        reject();
      });
  });
};

export const clearFilters = () => (dispatch) => {
  dispatch(setRegisterRangeParam(registerRangeEnum.today));
  dispatch(
    setReportFilters({
      startAt: moment().format(ISODATE),
      endAt: moment().format(ISODATE)
    })
  );
  dispatch(setRegisterStatusParam(registerStatusEnum.all));
  dispatch(fetchRegisterReportList());
};

export const registerManually = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    api
      .post("/averbamais-engine/v1/register", data)
      .then((response) => {
        message.success(i18n.t("register.manually.successMessage"));
        dispatch(setLoading(false));
        resolve(response.data.id);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        const { errors } = error.response.data;
        if (Array.isArray(errors)) {
          dispatch(setErrors(errors));
        } else {
          message.error(error.response.data.message);
        }
        reject();
      });
  });
};

export const saveRegisterData = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setChangedRegisterData(data));
    resolve(data);
  });
};

export const confirmRegister = (id) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    api
      .put(`/averbamais-engine/v1/register/${id}/confirm`)
      .then((response) => {
        dispatch(setLoading(false));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        message.error(error.response.data.message);
        reject();
      });
  });
};
export default registerSlice.reducer;

// cancel --------------------------------------------------

export const cancelRegisterManually = (id, cancelReason) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    api
      .put(`/averbamais-engine/v1/register/${id}/cancel`, { cancelReason })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        } else {
          message.error(error.response.data.message);
        }
        reject();
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const approveCancellation = (id) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    api
      .put(`/averbamais-engine/v1/register/${id}/approve`)
      .then((response) => {
        message.success(i18n.t("cancel.approve.successMessage"));
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        reject();
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const refuseCancellation = (id, refuseReason) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    api
      .put(`/averbamais-engine/v1/register/${id}/refuse`, { refuseReason })
      .then((response) => {
        message.success(i18n.t("cancel.refuse.successMessage"));
        resolve(response);
      })
      .catch((error) => {
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        } else {
          message.error(error.response.data.message);
        }
        reject();
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const confirmCancelRegister = (cancelId) => (dispatch) => {
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    api
      .put(`/averbamais-engine/v1/register/${cancelId}/cancel/confirm`)
      .then((response) => {
        message.success(i18n.t("cancel.confirm.successMessage"));
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        reject();
      })
      .finally(() => dispatch(setLoading(false)));
  });
};
