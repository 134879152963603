import React from "react";
import { Form, Input } from "antd";
// custom styles
import "./styles.less";

const FormInput = ({
  label,
  name,
  placeholder,
  error,
  rules,
  ...restProps
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      help={error}
      validateStatus={error && "error"}
      className="formInput gx-p-1"
      rules={rules}
    >
      <Input placeholder={placeholder} {...restProps} />
    </Form.Item>
  );
};

export default FormInput;
