import React from "react";
import "./styles.less";
// logo
const logo = require(`@/assets/images/${process.env.REACT_APP_BRAND}_colorido.png`);
// custom styles

const SigninHeader = ({ type, title }) => {
  return (
    <div className="signin-header">
      <img // TODO: O logo da mutuus deve ser aumentado
        className={`signin-logo ${type}Logo`}
        src={logo.default}
        alt="logo"
      />
      <h4 className="signin-title">{title}</h4>
    </div>
  );
};

export default SigninHeader;
