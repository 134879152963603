import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Form } from "antd";
import moment from "moment";

const FormWrapper = ({
  title,
  spinning,
  children,
  onSubmit,
  dataReference,
  subtitle,
  errors,
  ...resProps
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const populateFormItens = () => {
      let toRegisterFields = {};
      const formFields = form.getFieldsValue();

      Object.keys(formFields).forEach((key) => {
        if (key === "startAt" || key === "endAt") {
          toRegisterFields[key] = moment(dataReference[key]);
        } else {
          toRegisterFields[key] = dataReference[key];
        }
      });
      return toRegisterFields;
    };

    if (dataReference) {
      form.setFieldsValue(populateFormItens());
    }
  }, [dataReference, form, errors]);

  useEffect(() => {
    const setErrors = () => {
      let toRegisterErrors = [];
      const formFields = form.getFieldsValue();
      Object.keys(formFields).forEach((key) => {
        let temp = errors.filter((item) => item.field === key);
        if (temp.length > 0) {
          toRegisterErrors.push({
            name: key,
            errors: [temp[0].message],
            value: null
          });
        } else {
          toRegisterErrors.push({
            name: key,
            errors: null
          });
        }
      });
      return toRegisterErrors;
    };

    if (errors && Array.isArray(errors)) {
      form.setFields(setErrors());
    }
  }, [errors, form]);

  return (
    <div className="gx-pt-3">
      <Row justify="left">
        <Form
          colon={false}
          layout="horizontal"
          className="gx-w-100"
          onFinish={onSubmit}
          form={form}
          {...resProps}
        >
          {children}
        </Form>
      </Row>
    </div>
  );
};
FormWrapper.propTypes = {
  title: PropTypes.string,
  spinning: PropTypes.bool,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  dataReference: PropTypes.object,
  subtitle: PropTypes.string,
  errors: PropTypes.array
};

export default FormWrapper;
