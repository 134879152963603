import React, { useEffect } from "react";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../store/slices/common";
// components
import { RegisterViewer, LabelField, BorderCard } from "../../../components";
// util
import {
  formatDateToDisplayWithHour,
  formatProtocolType
} from "../../../util/formatters";

const CancelProtocol = () => {
  const { registerData } = useSelector((state) => state.register);

  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentStep(2));
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col md={24} lg={14} className="gx-p-0">
          <LabelField
            labelWidth={160}
            label={translation("register.protocolLabel")}
          >
            <span>{registerData.protocol}</span>
          </LabelField>
        </Col>
        <Col md={24} lg={10} className="gx-p-0">
          <LabelField
            labelWidth={176}
            label={translation("register.protocolDateHourLabel")}
          >
            <span>{formatDateToDisplayWithHour(registerData.registerAt)}</span>
          </LabelField>
        </Col>
      </Row>
      {registerData.protocols &&
        registerData.protocols.map((item, index) => (
          <Row key={index}>
            <Col md={24} className="gx-p-0">
              <LabelField
                labelWidth={160}
                label={translation(
                  `register.protocolNumberLabel.${formatProtocolType(
                    item.type
                  )}`
                )}
              >
                <span>{item.registerNumber}</span>
              </LabelField>
            </Col>
          </Row>
        ))}
      <RegisterViewer registerData={registerData} />
      {registerData.protocols &&
        registerData.protocols.map((item, index) => (
          <Row key={index}>
            <Col md={24} className="gx-p-0">
              <LabelField
                labelWidth={160}
                label={translation(
                  `reportViewer.coverage.${formatProtocolType(item.type)}`
                )}
              />
            </Col>
          </Row>
        ))}
      <Row>
        <Col md={24} lg={14} className="gx-p-0">
          <LabelField
            labelWidth={160}
            label={translation("cancel.manually.cancelReasonLabel")}
          >
            <span>{registerData.cancelReason}</span>
          </LabelField>
        </Col>
      </Row>
      <Row justify="center">
        <BorderCard className="protocol-card" style={{ maxWidth: 403 }}>
          <Row justify="center" className="gx-mb-20">
            <h5>{translation("cancel.card.title")}</h5>
          </Row>
          <Row justify="center">
            <LabelField
              labelWidth={200}
              label={translation("cancel.card.cancelledAtLabel")}
            >
              <span>
                {formatDateToDisplayWithHour(registerData.canceledAt)}
              </span>
            </LabelField>
          </Row>
        </BorderCard>
      </Row>
    </>
  );
};

export default CancelProtocol;
