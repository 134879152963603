import React, { useState } from "react";
// hooks
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// antd
import { Col, Row, Input } from "antd";
// components
import {
  InputDateRange,
  InputSelect,
  InputCurrency,
  FormWrapper,
  ActionButton,
  NewTable,
  PageHeader,
  FormMaskedInput,
  FormLocationInput
} from "../../../components";
// util
import { registerRange, registerStatus } from "../../../util/Options";
import { registerRangeEnum, registerStatusEnum } from "../../../util/Enums";
import { formatDateToDisplay, formatToDate } from "../../../util/formatters";
// redux
import {
  fetchRegisterReportList,
  generateRangeValue,
  setRegisterStatusParam,
  setReportFilters,
  clearFilters,
  appendFilters
} from "../../../store/slices/sections/register";
// custom hook
import { useRedirect } from "../../../hooks";
// paths
import { PATH_REPORT, PATH_CONFIRM, PATH_CANCEL } from "../../../routes/paths";
// mask
import { CNPJ_MASK_STRING } from "../../../util/Masks";

const Report = ({ title, previous, pageTag, children }) => {
  const [errors, setErrors] = useState([]);

  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const {
    reportFilters: { startAt, endAt, status },
    rangeParam,
    reportList
  } = useSelector(({ register }) => register);

  const { redirect } = useRedirect();

  const columns = [
    {
      title: translation("register.reportIssuedAtColumn"),
      dataIndex: "issuedAt",
      key: 3,
      align: "center",
      render: (text) => formatDateToDisplay(text)
    },
    {
      title: translation("register.reportOriginAndDestinationColumn"),
      dataIndex: "originAndDestination",
      key: 4,
      align: "center"
    },
    {
      title: translation("register.reportMerchandiseValueColumn"),
      dataIndex: "merchandiseValue",
      key: 5,
      align: "center",
      render: (text) => <InputCurrency value={text} displayType="text" />
    },
    {
      title: translation("register.reportProtocolColumn"),
      dataIndex: "protocol",
      key: 6,
      align: "center",
      responsive: ["md"]
    },
    {
      title: "Status",
      dataIndex: "status",
      key: 7,
      align: "center",
      responsive: ["md"],
      render: (text) => {
        const item = registerStatus.find((item) => item.value === text);
        return item ? item.label : "";
      }
    }
  ];

  const handleChangeRange = (event) => {
    dispatch(generateRangeValue(event));
  };

  const handleRangePicker = (event) => {
    if (event) {
      dispatch(
        setReportFilters({
          startAt: formatToDate(event[0]),
          endAt: formatToDate(event[1])
        })
      );
    } else {
      dispatch(setReportFilters({}));
    }
  };

  const handleChangeStatus = (event) => {
    dispatch(setRegisterStatusParam(event));
  };

  const handleChangeCompany = (event) => {
    dispatch(
      appendFilters({
        company: event.replace(/[^A-Z0-9]/gi, "")
      })
    );
  };

  const handleChangeDocumentNumber = (event) => {
    dispatch(
      appendFilters({
        documentNumber: event.target.value
      })
    );
  };

  const handleChange = (event, name) => {
    dispatch(
      appendFilters({
        [name]: event
      })
    );
    setErrors([]);
  };

  const showRangeValues = () => {
    if (rangeParam === registerRangeEnum.today) {
      return `${formatDateToDisplay(startAt)}`;
    }

    if (startAt && endAt) {
      return `${formatDateToDisplay(startAt)} a ${formatDateToDisplay(endAt)}`;
    }
    return null;
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  const handleOnRow = (record) => {
    if (
      record.status === registerStatusEnum.registered ||
      record.status === registerStatusEnum.cancelled
    ) {
      return redirect(`${PATH_REPORT.root}/${record.id}`);
    }

    if (record.status === registerStatusEnum.pending) {
      return redirect(`${PATH_REPORT.root}${PATH_CONFIRM.root}/${record.id}`);
    }

    if (record.status === registerStatusEnum.cancel_in_analysis) {
      return redirect(`${PATH_CANCEL.root}/outros/${record.id}`);
    }

    return null;
  };

  const processTableData = (list) => {
    return {
      ...list,
      data: list.data.map((item, index) => {
        return {
          ...item,
          key: index,
          originAndDestination: `${item.origin || ""} - ${
            item.destination || ""
          }`
        };
      })
    };
  };

  const onCitySearchError = (fieldName) => {
    setErrors([
      {
        field: fieldName,
        message: translation("register.manually.citySearchError")
      }
    ]);
  };

  const getSearchErrorsMessage = (fieldName) => {
    const objectErrorr = errors.find((item) => item.field === fieldName);
    if (objectErrorr) {
      return objectErrorr.message;
    }
    return null;
  };

  const handlePaginationChange = (pageNumber) => {
    dispatch(appendFilters({ pageNumber: pageNumber - 1 }));
    dispatch(fetchRegisterReportList());
  };

  return (
    <>
      <PageHeader title={title} previous={previous} pageTag={pageTag} />
      {children || (
        <FormWrapper
          title={translation("register.reportTitle")}
          subtitle={translation("register.reportSubTitle")}
        >
          <Row className="gx-m-0">
            <Col md={24}>
              <span className="gx-font-weight-semi-bold">
                {translation("register.reportFilterTitle")}
              </span>
              <Row className="gx-mb-10" align="middle" justify="start">
                <Col sm={24} md={4}>
                  <span>{translation("register.reportRangeLabel")}</span>
                </Col>
                <Col sm={24} md={8}>
                  <InputSelect
                    options={registerRange}
                    className="gx-w-100 gx-p-1"
                    value={rangeParam}
                    onChange={handleChangeRange}
                  />
                </Col>
                <Col sm={24} md={8}>
                  <span>{showRangeValues()}</span>
                </Col>
              </Row>
              {rangeParam === registerRangeEnum.selectPeriod && (
                <Row className="gx-m-0 gx-mb-10" align="middle" justify="start">
                  <Col md={8} push={4}>
                    <div className="gx-w-100 gx-mb-10 gx-px-1">
                      <InputDateRange
                        onChange={handleRangePicker}
                        className="gx-w-100"
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="gx-mb-10" align="middle">
                <Col sm={24} md={4}>
                  <span>{translation("register.reportStatusLabel")}</span>
                </Col>
                <Col sm={24} md={8}>
                  <InputSelect
                    options={registerStatus}
                    className="gx-w-100 gx-p-1"
                    value={status}
                    onChange={handleChangeStatus}
                  />
                </Col>
              </Row>

              <Row className="gx-m-0" align="middle">
                <Col sm={24} md={4}>
                  <span className="gx-d-block gx-mb-10">
                    {translation("report.filter.companyLabel")}
                  </span>
                </Col>
                <Col sm={24} md={8} className="gx-m-0">
                  <FormMaskedInput
                    name="company"
                    mask={CNPJ_MASK_STRING}
                    placeholder={translation(
                      "report.filter.companyPlaceholder"
                    )}
                    onChange={handleChangeCompany}
                  />
                </Col>
                <Col sm={24} md={4}>
                  <span className="gx-d-block gx-mb-10">
                    {translation("report.filter.documentNumberLabel")}
                  </span>
                </Col>
                <Col sm={24} md={8}>
                  <Input
                    name="documentNumber"
                    autocomplete="no"
                    placeholder={translation(
                      "register.manually.documentNumberPlaceholder"
                    )}
                    className="gx-mb-10"
                    onChange={(e) => handleChangeDocumentNumber(e)}
                  />
                </Col>
              </Row>
              <Row className="gx-mb-30" align="middle">
                <Col sm={24} md={4}>
                  <span className="gx-d-block gx-mb-10">
                    {translation("report.filter.originLabel")}
                  </span>
                </Col>
                <Col sm={24} md={8}>
                  <FormLocationInput
                    name="origin"
                    placeholder={translation("report.filter.originPlaceholder")}
                    error={getSearchErrorsMessage("origin")}
                    onChange={(e) => handleChange(e, "origin")}
                    onSearchError={() => onCitySearchError("origin")}
                  />
                </Col>
                <Col sm={24} md={4}>
                  <span className="gx-d-block gx-mb-10">
                    {translation("report.filter.destinationLabel")}
                  </span>
                </Col>
                <Col sm={24} md={8}>
                  <FormLocationInput
                    name="destination"
                    placeholder={translation(
                      "report.filter.destinationPlaceholder"
                    )}
                    error={getSearchErrorsMessage("destination")}
                    onChange={(e) => handleChange(e, "destination")}
                    onSearchError={() => onCitySearchError("destination")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" className="gx-m-0">
            <ActionButton onClick={handleClearFilters} type="primary" ghost>
              {translation("register.reportClearFilterLabel")}
            </ActionButton>
            <ActionButton
              type="primary"
              onClick={() => dispatch(fetchRegisterReportList())}
            >
              {translation("register.reportSearchFilterLabel")}
            </ActionButton>
          </Row>
          <Row>
            <NewTable
              columns={columns}
              tableLayout="fixed"
              requestData={processTableData(reportList)}
              requestAction={fetchRegisterReportList}
              onPaginationChange={handlePaginationChange}
              onRow={(record) => {
                return {
                  className: "clickable-row",
                  onClick: () => handleOnRow(record)
                };
              }}
            />
          </Row>
        </FormWrapper>
      )}
    </>
  );
};

export default Report;
