import React, { useState } from "react";
import { Modal, Menu, Button } from "antd";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import { logout, fetchBrokerInsuredSelection } from "../../store/slices/auth";
import {
  setCurrentMenuKeys,
  fetchCompaniesList,
  setCompaniesList
} from "../../store/slices/common";

import { THEME_TYPE_LITE } from "../../theme/constants/ThemeSetting";
import SidebarBrand from "./SidebarBrand";
import CustomScrollbars from "../../util/CustomScrollbars";
import { userTypes } from "../../util/Enums";
import InsuredSearchModal from "../InsuredSearchModal";
// styles
import "./styles.less";
// custom hook
import { useRedirect } from "../../hooks";
// paths
import {
  PATH_HOME,
  PATH_REGISTER,
  PATH_PROFILE,
  PATH_USERS,
  PATH_COMPANY,
  PATH_POLICY,
  PATH_REPORT,
  PATH_AUTH,
  PATH_CANCEL,
  PATH_SINISTER
} from "../../routes/paths";
import { userOrigins } from "../../constants";
import ClientPortalSidebarContent from "./ClientPortalSidebarContent";

const { SubMenu } = Menu;
const rootSubmenuKeys = ["registration", "account"];

const SidebarContent = () => {
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [filterParams, setFilterParams] = useState({});

  const { themeType } = useSelector(({ settings }) => settings);
  const { t: translation } = useTranslation();
  const {
    auth: { userType, user },
    routesParams: { paramsData },
    common: { companiesList, currentMenuKeys, fetching },
    dashboard: { isFetchingAll }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { fullRoute, redirect } = useRedirect();

  const handleLogout = () => {
    const { confirm } = Modal;
    const confirmLogoutModal = () => {
      confirm({
        title: translation("logout.confirmModalTitle"),
        onOk() {
          dispatch(logout());
        },
        onCancel() {
          return false;
        },
        okText: translation("logout.confirmModalOkButton"),
        cancelText: translation("logout.confirmModalCancelButton")
      });
    };
    confirmLogoutModal();
  };

  const selectInsuredAndRedirect = (event) => {
    dispatch(fetchBrokerInsuredSelection(event)).then(() => {
      redirect(PATH_HOME.root);
      handleClearFilterParams();
      setSearchModalVisible(false);
    });
  };

  const onClick = (key) => {
    if (key) {
      dispatch(setCurrentMenuKeys(key.keyPath));
    }
  };

  const onOpenChange = (keys) => {
    if (currentMenuKeys) {
      const latestOpenKey = keys.find(
        (key) => currentMenuKeys.indexOf(key) === -1
      );
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        dispatch(setCurrentMenuKeys(keys));
      } else {
        dispatch(setCurrentMenuKeys(latestOpenKey ? [latestOpenKey] : []));
      }
    }
  };

  const handleFilterParamsChange = (field, event) => {
    if (event.target) {
      return setFilterParams({ ...filterParams, [field]: event.target.value });
    }
    return setFilterParams({
      ...filterParams,
      [field]: event.replace(/[^A-Z0-9]/gi, "")
    });
  };

  const handleFetchCompaniesList = () => {
    dispatch(fetchCompaniesList(filterParams));
  };

  const handleClearFilterParams = () => {
    setFilterParams({});
    dispatch(setCompaniesList([]));
  };

  const handleCancelSearchModal = () => {
    handleClearFilterParams();
    return !fetching && setSearchModalVisible(false);
  };

  return (
    <>
      <InsuredSearchModal
        loading={fetching || isFetchingAll}
        visible={searchModalVisible}
        companyList={companiesList}
        onCancel={handleCancelSearchModal}
        onChange={handleFilterParamsChange}
        onSubmit={handleFetchCompaniesList}
        onSelect={selectInsuredAndRedirect}
        onClear={handleClearFilterParams}
      />
      <div className="gx-sidebar-content">
        <SidebarBrand />
        <div className="gx-pl-4 gx-pb-1 sidebar-user">
          <h4 className="title-user">{`${translation(
            "sidebar.userNameLabel"
          )} ${user.name || ""}`}</h4>
          {userType === userTypes.BROKER && (
            // <CompaniesSelector
            //   loading={isFetchingAll}
            //   disabled={isFetchingAll}
            //   options={formattedCompaniesList()}
            //   placeholder="Selecione o segurado"
            //   className="insured-select"
            //   onChange={selectInsuredAndRedirect}
            //   value={user.documentNumber}
            // />
            <Button
              disabled={isFetchingAll}
              loading={isFetchingAll}
              onClick={() => setSearchModalVisible(true)}
            >
              {translation("sidebar.searchButton")}
            </Button>
          )}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {userType === userTypes.CUSTOMER &&
          paramsData?.origin === userOrigins.CLIENT_PORTAL_APP ? (
            <ClientPortalSidebarContent />
          ) : (
            <Menu
              openKeys={currentMenuKeys || []}
              selectedKeys={currentMenuKeys || []}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
              onClick={onClick}
              onOpenChange={onOpenChange}
            >
              {userType !== userTypes.BROKER ||
              (userType === userTypes.BROKER && user.insuredDocumentNumber) ? (
                <>
                  <Menu.Item key="home">
                    <Link to={fullRoute(PATH_HOME.root)}>
                      <span>{translation("sidebar.home")}</span>
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="registration"
                    title={translation("sidebar.registration")}
                    className="sidebar-content-menu"
                  >
                    <Menu.Item key="register">
                      <Link to={fullRoute(PATH_REGISTER.root)}>
                        <span>
                          {translation("sidebar.registration.register")}
                        </span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="report">
                      <Link to={fullRoute(PATH_REPORT.root)}>
                        <span>
                          {translation("sidebar.registration.report")}
                        </span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="cancel">
                      <Link to={fullRoute(PATH_CANCEL.root)}>
                        <span>
                          {translation("sidebar.registration.cancel")}
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="policy">
                    <Link to={fullRoute(PATH_POLICY.root)}>
                      <span>{translation("sidebar.policy")}</span>
                    </Link>
                  </Menu.Item>
                </>
              ) : null}
              <SubMenu
                key="account"
                title={translation("sidebar.account")}
                className="sidebar-content-menu"
              >
                {userType !== "BROKER" ||
                (userType === "BROKER" && user.insuredDocumentNumber) ? (
                  <Menu.Item key="user">
                    <Link to={fullRoute(PATH_USERS.root)}>
                      <span>{translation("sidebar.account.user")}</span>
                    </Link>
                  </Menu.Item>
                ) : null}
                {userType !== "BROKER" ||
                (userType === "BROKER" && user.insuredDocumentNumber) ? (
                  <Menu.Item key="company">
                    <Link to={fullRoute(PATH_COMPANY.root)}>
                      <span>{translation("sidebar.account.company")}</span>
                    </Link>
                  </Menu.Item>
                ) : null}
                <Menu.Item key="profile">
                  <Link to={fullRoute(PATH_PROFILE.root)}>
                    <span>{translation("sidebar.account.profile")}</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="update-password">
                  <Link to={fullRoute(PATH_AUTH.changePassword)}>
                    <span>{translation("sidebar.account.updatePassword")}</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {(userType !== userTypes.BROKER ||
                (userType === userTypes.BROKER &&
                  user.insuredDocumentNumber)) && (
                <Menu.Item key="sinister">
                  <Link to={fullRoute(PATH_SINISTER.root)}>
                    <span>{translation("sidebar.sinisterItem")}</span>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="logout">
                <span onClick={handleLogout}>
                  {translation("sidebar.logout")}
                </span>
              </Menu.Item>
            </Menu>
          )}
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
