import React from "react";
import PropTypes from "prop-types";
import { Form, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const FormField = ({ error, label, children, htmlFor, tooltip, ...rest }) => {
  const hasError = Boolean(error);
  return (
    <Form.Item
      hasFeedback={hasError}
      validateStatus={hasError ? "error" : null}
      help={error}
      style={{ margin: 0 }}
      htmlFor={htmlFor || (children.props ? children.props.id : null)}
      label={
        label ? (
          <span>
            {label}
            {tooltip ? (
              <Tooltip title={tooltip}>
                <QuestionCircleOutlined />
              </Tooltip>
            ) : null}
          </span>
        ) : null
      }
      {...rest}
    >
      {children}
    </Form.Item>
  );
};

FormField.propTypes = {
  error: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.node,
  htmlFor: PropTypes.any,
  tooltip: PropTypes.string
};

export default FormField;
