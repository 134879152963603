import React from "react";
// antd
import { Row } from "antd";
// translations
import { useTranslation } from "react-i18next";
// redux
import { useSelector } from "react-redux";
// components
import { PageHeader, ActionButton, InsurerData } from "../../components";

const Sinister = ({ title, previous }) => {
  const {
    insurers: { insurers }
  } = useSelector((state) => state.auth);

  const { t: translation } = useTranslation();

  return (
    <>
      <PageHeader title={title} previous={previous} />
      <Row className="gx-mb-2">
        <h4>{translation("sinister.subTitle")}</h4>
      </Row>
      <h4>
        {translation("sinister.southernRegionLabel")}&nbsp;
        <span className="gx-font-weight-semi-bold">
          {translation("sinister.southernRegionPhone")}
        </span>
      </h4>
      <h4 className="gx-mb-5">
        {translation("sinister.otherRegionLabel")}&nbsp;
        <span className="gx-font-weight-semi-bold">
          {translation("sinister.otherRegionPhone")}
        </span>
      </h4>
      <Row className="gx-mb-2">
        <h4>{translation("sinister.contactInsurerLabel")}</h4>
      </Row>
      <h4>
        <span className="gx-font-weight-semi-bold">
          {translation("sinister.brokerPhone")}
        </span>
        &nbsp;
        {translation("sinister.brokerLabel")}
      </h4>
      {/* {insurers.map((insurer, index) => (
        <InsurerData key={index} insurer={insurer} />
      ))} */}
      {/* <Row justify="center">
        <ActionButton size="large" type="primary">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe_Z3XoJlsNBBwo6tMxv7kDStSJLZWr3yONDWEAiWPPOO1x6Q/viewform"
          >
            {translation("sinister.reportSinisterButton")}
          </a>
        </ActionButton>
      </Row> */}
    </>
  );
};

export default Sinister;
