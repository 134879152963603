import React from "react";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { DATEMASK } from "../../util/Masks";

const InputDate = (props) => (
  <DatePicker
    locale={locale}
    format={DATEMASK}
    className="gx-w-100"
    {...props}
  />
);

export default InputDate;
