import React from "react";
// prop types
import PropTypes from "prop-types";
import "./styles.less";

const BorderCard = ({ children, className, ...restProps }) => (
  <div className={`borderCard ${className}`} {...restProps}>
    {children}
  </div>
);

BorderCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default BorderCard;
