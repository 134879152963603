import React from "react";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { DATEMASK } from "../../util/Masks";

const { RangePicker } = DatePicker;

const InputDateRange = (props) => {
  return <RangePicker locale={locale} format={DATEMASK} {...props} />;
};

export default InputDateRange;
