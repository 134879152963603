import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col } from "antd";
// redux
import { useSelector } from "react-redux";
// components
import { PageHeader, Stepper } from "../../../components";
import CancelByXML from "./CancelByXML";
// constants
import { cancelSteps } from "../../../util/Options";

const Cancel = ({ children, previous }) => {
  const { currentStep } = useSelector((state) => state.common);

  const { t: translation } = useTranslation();

  return (
    <>
      <PageHeader title={translation("cancel.pageTitle")} previous={previous} />
      <Row justify="center" className="gx-mb-30">
        <Col sm={24} md={22}>
          <Stepper steps={cancelSteps} current={currentStep} />
        </Col>
      </Row>
      {children || <CancelByXML />}
    </>
  );
};

Cancel.propTypes = {
  children: PropTypes.node
};

export default Cancel;
