import React from "react";
// route
import { Redirect } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// hooks
import { useRedirect } from "../hooks";
// paths
import { PATH_AUTH } from "../routes/paths";
// contants
import { userStatus } from "../util/Enums";

const UserStatusGuard = ({ children }) => {
  const {
    user: { status }
  } = useSelector((state) => state.auth);

  const { fullRoute } = useRedirect();

  if (status === userStatus.NEED_UPDATE_PASSWORD) {
    return <Redirect to={fullRoute(PATH_AUTH.updatePassword)} />;
  }

  return <>{children}</>;
};

export default UserStatusGuard;
