import React from "react";
// prop types
import PropTypes from "prop-types";
// antd
import { Button } from "antd";
import "./styles.less";

const ActionButton = ({ children, className, ...props }) => (
  <Button
    className={`${className} ${
      !props.ghost && !props.danger ? "primaryButton" : ""
    }`}
    {...props}
  >
    {children}
  </Button>
);

ActionButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ghost: PropTypes.bool,
  danger: PropTypes.bool
};

export default ActionButton;
