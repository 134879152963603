import React from "react";
import PropTypes from "prop-types";
// route
import { Redirect, useLocation } from "react-router-dom";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../store/slices/auth";
// hooks
import { useRedirect } from "../hooks";
// paths
import { PATH_AUTH, PATH_REGISTER } from "../routes/paths";

const AuthGuard = ({ children }) => {
  const { userToken } = useSelector((state) => state.auth);
  const { fullRoute } = useRedirect();
  const location = useLocation();
  const dispatch = useDispatch();

  if (location.pathname.includes(PATH_REGISTER.root)) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    if (token && token !== "null") {
      dispatch(setToken(token));
    }
  }

  if (!userToken) {
    return <Redirect to={fullRoute(PATH_AUTH.login)} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
