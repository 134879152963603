import React from "react";
// prop types
import PropTypes from "prop-types";
// Hooks
import { useTranslation } from "react-i18next";
// antd
import { Col, Row } from "antd";
import NumberFormat from "react-number-format";
import { PHONEMASK_STRING } from "../../../../util/Masks";
// formatters
import { formatDateToDisplay } from "../../../../util/formatters";
// components
import { FormWrapper, InputCurrency, LabelField } from "../../../../components";

const StaticPolicyView = ({
  data = {},
  renderSelectedTypeOption,
  errors = [],
  dataReference,
  renderSelectedInsurer
}) => {
  const { t: translation } = useTranslation();
  const labelSize = 230;

  const handleChange = () => {};

  return (
    <FormWrapper
      title={translation("policy.pageTitle")}
      dataReference={dataReference}
      onValuesChange={handleChange}
      errors={errors}
    >
      <Row>
        <Col sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.typeLabel")}
          >
            {renderSelectedTypeOption()}
          </LabelField>
        </Col>
        <Col sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.insurerLabel")}
          >
            {renderSelectedInsurer()}
          </LabelField>
        </Col>
        <Col sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.policyNumberLabel")}
          >
            {data.policyNumber}
          </LabelField>
        </Col>
        <Col sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.maximumLimitLabel")}
          >
            <InputCurrency
              displayType="text"
              value={data.maximumGuaranteeLimit}
            />
          </LabelField>
        </Col>
        <Col sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.startAtLabel")}
          >
            {formatDateToDisplay(data.startAt)}
          </LabelField>
        </Col>
        <Col sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.endAtLabel")}
          >
            {formatDateToDisplay(data.endAt)}
          </LabelField>
        </Col>
      </Row>
      <Row className="gx-mt-2 gx-mb-2">
        <Col md={24} className="gx-p-0">
          <h4>{translation("policy.details.subtitle")}</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.policyBrokerNameLabel")}
          >
            {data.broker?.name}
          </LabelField>
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.policyBrokerEmailLabel")}
          >
            {data.broker?.email}
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col sm={8} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.policyBrokerPhoneLabel")}
          >
            <NumberFormat
              value={data.broker?.contactPhoneNumber}
              format={PHONEMASK_STRING}
              displayType="text"
            />
          </LabelField>
        </Col>
        <Col>
          <LabelField
            labelWidth={labelSize}
            label={translation("policy.policyBrokerWhatsappLabel")}
          >
            <NumberFormat
              value={data?.broker?.contactPhoneNumber}
              format={PHONEMASK_STRING}
              displayType="text"
            />
          </LabelField>
        </Col>
      </Row>
    </FormWrapper>
  );
};

StaticPolicyView.propTypes = {
  data: PropTypes.object,
  renderSelectedTypeOption: PropTypes.func,
  errors: PropTypes.array,
  dataReference: PropTypes.object,
  renderSelectedInsurer: PropTypes.func
};

export default StaticPolicyView;
