import React from "react";
// router
import { useHistory } from "react-router-dom";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { LeftOutlined } from "@ant-design/icons";
// custom components
import ActionButton from "../ActionButton";
import TagLabel from "../TagLabel";
// custom hook
import { useRedirect } from "../../hooks";
// path
import { PATH_HOME } from "../../routes/paths";
// custom styles
import "./style.less";

const PageHeader = ({ title, previous, pageTag, hasBackButton = true }) => {
  const { t: translation } = useTranslation();
  const { redirect } = useRedirect();
  const history = useHistory();

  const handleGoBack = () => {
    if (history.length > 2) {
      return previous ? redirect(previous) : history.goBack();
    }
    return redirect(PATH_HOME.root);
  };

  return (
    <div className="pageLayoutHeader">
      <h3 className="pageLayoutTitle">{title}</h3>
      <div className="pageLayoutCTA">
        {hasBackButton && (
          <ActionButton
            onClick={handleGoBack}
            className="layoutBackButton"
            type="text"
            size="large"
            icon={<LeftOutlined />}
          >
            {translation("pageLayout.backButton")}
          </ActionButton>
        )}
        {pageTag ? (
          <TagLabel type={pageTag.type} className="pageLayoutTag">
            {pageTag.label}
          </TagLabel>
        ) : null}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  previous: PropTypes.string,
  pageTag: PropTypes.object,
  hasBackButton: PropTypes.bool
};

export default PageHeader;
