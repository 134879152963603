import React from "react";
import { Form, Input } from "antd";
// custom styles
import "./styles.less";

const FormPassword = ({
  label,
  name,
  placeholder,
  className,
  rules,
  ...restProps
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className={`formPassword ${className}`}
      rules={rules}
    >
      <Input.Password placeholder={placeholder} {...restProps} />
    </Form.Item>
  );
};

export default FormPassword;
