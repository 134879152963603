import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { api } from "../../../../util/Api";
import { datePeriods } from "../../../../constants";
import { generateQueryString } from "../../../../util/Helpers";
import { registerStatusEnum } from "../../../../util/Enums";
import { setLoading, setFetching } from "../../common";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    registeredTotal: 0,
    goodsAmountTotal: 0,
    pendentRegisterTotal: 0,
    boardingTotal: 0,
    goodsMerchandiseTotal: [],
    registerAmountByDestination: [],
    todayRegisterList: [],
    isFetchingAll: false
  },
  reducers: {
    setGoodsAmountTotal: (state, action) => {
      state.goodsAmountTotal = action.payload;
    },
    setPendentRegisterTotal: (state, action) => {
      state.pendentRegisterTotal = action.payload;
    },
    setRegisteredTotal: (state, action) => {
      state.registeredTotal = action.payload;
    },
    setGoodsMerchandiseTotal: (state, action) => {
      state.goodsMerchandiseTotal = action.payload;
    },
    setRegisterAmountByDestination: (state, action) => {
      state.registerAmountByDestination = action.payload;
    },
    setTodayRegisterList: (state, action) => {
      state.todayRegisterList = action.payload;
    },
    setBoardingTotal: (state, action) => {
      state.boardingTotal = action.payload;
    },
    setFetchingAll: (state, action) => {
      state.isFetchingAll = action.payload;
    }
  }
});
export const {
  setGoodsAmountTotal,
  setPendentRegisterTotal,
  setGoodsMerchandiseTotal,
  setRegisteredTotal,
  setRegisterAmountByDestination,
  setTodayRegisterList,
  setBoardingTotal,
  setFetchingAll
} = dashboardSlice.actions;

export const setAllDashboard = () => async (dispatch) => {
  dispatch(setFetchingAll(true));
  try {
    await Promise.all([
      dispatch(fetchGoodsTotal()),
      dispatch(fetchPendentRegisterTotal()),
      dispatch(fetchGoodsMerchandiseTotal()),
      dispatch(fetchRegisterDashboardList())
    ]);
    dispatch(setFetchingAll(false));
  } catch {
    dispatch(setFetchingAll(false));
  }
};

export const fetchGoodsTotal = () => (dispatch) => {
  const queryString = generateQueryString(datePeriods.month);
  dispatch(fetchBoardingTotal());
  return new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/summary/goodsTotal/${queryString}`)
      .then((response) => {
        dispatch(setGoodsAmountTotal(response.data.total || 0));
        return resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        return reject(error);
      });
  });
};

export const fetchRegisterTotalByStatus = (status, setAction) => (dispatch) => {
  const queryString = generateQueryString({
    ...datePeriods.month,
    status
  });
  return new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/summary/register/${queryString}`)
      .then((response) => {
        if (response.data.total) {
          dispatch(setAction(response.data.total));
        }
        return resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        return reject(error);
      });
  });
};

export const fetchPendentRegisterTotal = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      fetchRegisterTotalByStatus(
        registerStatusEnum.pending,
        setPendentRegisterTotal
      )
    )
      .then(() => resolve())
      .catch((error) => reject(error));
  });

export const fetchRegisteredRegisterTotal = () => (dispatch) => {
  dispatch(
    fetchRegisterTotalByStatus(
      registerStatusEnum.registered,
      setRegisteredTotal
    )
  );
};

export const fetchGoodsMerchandiseTotal = () => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(FetchRegisterAmountByDestination());
  return new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/summary/sumMerchandise`)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data) {
          dispatch(setGoodsMerchandiseTotal(response.data));
        }
        return resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        message.error(error.response.data.message);
        return reject(error);
      });
  });
};

export const FetchRegisterAmountByDestination = () => (dispatch) =>
  new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/summary/registerTotalByDestination`)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data) {
          dispatch(setRegisterAmountByDestination(response.data));
        }
        return resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        message.error(error.response.data.message);
        return reject(error);
      });
  });

export const fetchRegisterDashboardList = () => (dispatch) => {
  dispatch(setFetching(true));

  dispatch(setTodayRegisterList([]));
  return new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/summary/today`)
      .then((response) => {
        dispatch(setFetching(false));
        if (response.data) {
          dispatch(setTodayRegisterList(response.data));
        }
        return resolve(response);
      })
      .catch((error) => {
        dispatch(setFetching(false));
        message.error(error.response.data.message);
        return reject(error);
      });
  });
};

export const fetchBoardingTotal = () => (dispatch) => {
  const queryString = generateQueryString({
    ...datePeriods.month
  });
  dispatch(setFetching(true));
  dispatch(setBoardingTotal(0));

  return new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/summary/boardingTotal/${queryString}`)
      .then((response) => {
        dispatch(setFetching(false));
        dispatch(setBoardingTotal(response?.data?.total));
        return resolve(response);
      })
      .catch((error) => {
        dispatch(setFetching(false));
        message.error(error?.response?.data?.message);
        return reject(error);
      });
  });
};

export default dashboardSlice.reducer;
