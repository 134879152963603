import React, { useEffect } from "react";
// antd
import { Col, Row, Modal } from "antd";
import NumberFormat from "react-number-format";
// router
import { useLocation, useParams } from "react-router-dom";
// translation hook
import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  updateUser,
  setErrors,
  sendPassword,
  fetchSelectedUser,
  setUser,
  clearUser,
  blockUser,
  setUserSaved,
  unBlockUser
} from "../../../store/slices/sections/users";
// custom hook
import { useRedirect } from "../../../hooks";
// paths
import { PATH_USERS } from "../../../routes/paths";
// components
import {
  FormWrapper,
  FormInput,
  FormRadioGroup,
  FormMaskedInput,
  ActionButton,
  PageHeader,
  LabelField
} from "../../../components";
// mask
import { COMPANY, PHONEMASK_NUMBER } from "../../../util/Masks";
// formatters
import { removeMask } from "../../../util/formatters";
// util
import { clearError } from "../../../util/Errors";
// options
import { userPermissions } from "../../../util/Options";
import { userStatus, userActions } from "../../../util/Enums";

const CreateUser = ({ title }) => {
  // state
  const {
    auth: { user, userType },
    users: { errors, selectedUser, userSaved },
    common: { loading }
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { redirect, fullRoute } = useRedirect();
  const { t: translation } = useTranslation();
  const userWillUpdate =
    useLocation().pathname !== fullRoute(PATH_USERS.newUser);
  const params = useParams();
  const { email, documentNumber } = params;
  const { confirm } = Modal;

  // functions
  const onValuesChanged = (data) => {
    dispatch(setUser(data));
    dispatch(setErrors(clearError(data, errors)));
  };

  const getDocumentNumber = () => {
    if (!userWillUpdate) {
      return userType === "BROKER"
        ? user.insuredDocumentNumber
        : user.documentNumber;
    }
    return selectedUser.documentNumber;
  };

  const onSubmit = (values) => {
    const { name, phoneNumber } = values;
    const data = {
      name,
      email: values.email ? values.email : email,
      phoneNumber: removeMask(phoneNumber),
      documentNumber: removeMask(user.documentNumber)
    };
    if (userWillUpdate) dispatch(updateUser(data));
    else dispatch(createUser(data));
  };

  const handleBlockUser = () => {
    dispatch(blockUser({ email, documentNumber }));
  };

  const handleUnblockUser = () => {
    dispatch(unBlockUser({ email, documentNumber }));
  };

  const handleSendPassword = () => dispatch(sendPassword([selectedUser]));

  const confirmModal = (actionType, action) => {
    const confirmCloseMessage = userWillUpdate
      ? translation("updateUser.updateUserConfirmMessage")
      : translation("createUser.createUserConfirmMessage");
    confirm({
      title:
        actionType === userActions.CLOSE
          ? confirmCloseMessage
          : translation(`users.${actionType}ConfirmTitle`),
      onOk() {
        action();
      },
      onCancel() {
        return false;
      },
      okText: translation("createUser.confirmOkButton"),
      cancelText: translation("createUser.confirmCancelButton")
    });
  };

  // TODO: é necessário ajustar esta função para abrir o modal com o novo botão de voltar
  // const handleRedirect = () => {
  //   return changed
  //     ? confirmModal(userActions.CLOSE, history.goBack)
  //     : history.goBack();
  // };
  const labelSize = 108;
  useEffect(() => {
    if (userSaved) {
      redirect(PATH_USERS.root);
    }
    return dispatch(setUserSaved(false));
  }, [userSaved, redirect, dispatch]);

  useEffect(() => {
    if (userWillUpdate) dispatch(fetchSelectedUser({ email, documentNumber }));
  }, [dispatch, email, documentNumber, userWillUpdate]);

  useEffect(() => {
    return () => {
      dispatch(clearUser());
      dispatch(setErrors());
    };
  }, [dispatch]);

  return (
    <>
      <PageHeader title={title} />
      <FormWrapper
        title={translation("createUser.pageTitle")}
        onSubmit={onSubmit}
        onValuesChange={onValuesChanged}
        errors={errors}
        dataReference={selectedUser}
      >
        <Row align="middle" className="gx-mb-3">
          <Col className="gx-p-0" xs={24} md={12}>
            <LabelField
              labelWidth={labelSize}
              label={translation("createUser.documentNumberLabel")}
            >
              <NumberFormat
                displayType="text"
                format={COMPANY}
                value={getDocumentNumber()}
              />
            </LabelField>
          </Col>
        </Row>
        {userWillUpdate ? (
          <Row align="middle" className="gx-mb-3">
            <Col className="gx-p-0" md={12} xs={24}>
              <LabelField
                labelWidth={labelSize}
                label={translation("createUser.emailLabel")}
              >
                <span className="gx-p-1 gx-mr-3">{email}</span>
              </LabelField>
            </Col>
          </Row>
        ) : null}
        <Row align="middle">
          <Col className="gx-p-0" xs={24} md={12}>
            <LabelField
              labelWidth={labelSize}
              label={translation("createUser.nameLabel")}
            >
              <FormInput
                name="name"
                placeholder={translation("createUser.namePlaceholder")}
              />
            </LabelField>
          </Col>
        </Row>
        {!userWillUpdate ? (
          <Row align="middle">
            <Col className="gx-p-0" xs={24} md={12}>
              <LabelField
                labelWidth={labelSize}
                label={translation("createUser.emailLabel")}
              >
                <FormInput
                  name="email"
                  placeholder={translation("createUser.emailPlaceholder")}
                />
              </LabelField>
            </Col>
          </Row>
        ) : null}
        <Row align="middle">
          <Col className="gx-p-0" xs={24} md={12}>
            <LabelField
              labelWidth={labelSize}
              label={translation("createUser.phoneNumberLabel")}
            >
              <FormMaskedInput
                name="phoneNumber"
                mask={PHONEMASK_NUMBER}
                placeholder={translation("createUser.phoneNumberPlaceholder")}
              />
            </LabelField>
          </Col>
        </Row>
        {userWillUpdate ? (
          <Row align="middle">
            <Col className="gx-p-0" md={10} xs={24}>
              <LabelField
                labelWidth={labelSize}
                label={translation("updateUser.statusLabel")}
              >
                <span className="gx-p-1 gx-mr-3">
                  {translation(`users.userStatus${selectedUser.status}`)}
                </span>
              </LabelField>
            </Col>
            <Col className="gx-p-0" md={10} xs={24}>
              {selectedUser.status === userStatus.BLOCKED ? (
                <ActionButton
                  size="middle"
                  className="gx-mb-0"
                  onClick={() =>
                    confirmModal(userActions.UNBLOCK, handleUnblockUser)
                  }
                  loading={loading}
                  type="primary"
                  ghost
                >
                  {translation("updateUser.unlockButton")}
                </ActionButton>
              ) : (
                <ActionButton
                  size="middle"
                  className="gx-mb-0"
                  onClick={() =>
                    confirmModal(userActions.BLOCK, handleBlockUser)
                  }
                  loading={loading}
                  type="primary"
                  ghost
                >
                  {translation("updateUser.blockButton")}
                </ActionButton>
              )}
            </Col>
          </Row>
        ) : null}
        <Row align="middle">
          <Col className="gx-p-0" md={24}>
            <LabelField
              labelWidth={labelSize}
              label={translation("createUser.permissionsLabel")}
            >
              <FormRadioGroup
                name="permissions"
                options={userPermissions}
                disabled
              />
            </LabelField>
          </Col>
        </Row>
        {userWillUpdate ? (
          <Row align="middle">
            <Col className="gx-p-0" md={24}>
              <ActionButton
                size="middle"
                className="gx-mb-0"
                onClick={handleSendPassword}
                type="primary"
                ghost
              >
                {translation("updateUser.sendNewPasswordButton")}
              </ActionButton>
            </Col>
          </Row>
        ) : null}
        <Row type="flex" align="center" className="gx-px-3 ">
          <ActionButton
            size="middle"
            className="gx-mb-0"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {translation("createUser.saveButton")}
          </ActionButton>
        </Row>
      </FormWrapper>
    </>
  );
};

export default CreateUser;
