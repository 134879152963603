import React from "react";
// translation
import { useTranslation } from "react-i18next";
// components
import { Row, Col } from "antd";
import { LabelField, RegisterViewer, BorderCard } from "../index";
import {
  formatDateToDisplayWithHour,
  formatProtocolType
} from "../../util/formatters";

const ReportViewer = ({ reportData = {} }) => {
  const { t: translation } = useTranslation();
  const labelSize = 200;

  const showDynamicProtocolType = () => {
    const types = reportData.protocols?.map((item) =>
      formatProtocolType(item.type)
    );
    // TODO: separador `e` temporário, quando a quantidade de types aumentar, trocar o separador
    return types?.join(" e ");
  };

  return (
    <>
      <RegisterViewer registerData={reportData} />
      <Row justify="center">
        <BorderCard className="protocol-card" style={{ maxWidth: 850 }}>
          <div className="card-header">
            <h4>{translation("register.protocolConfirmLabel")}</h4>
          </div>
          <Col md={24} lg={4} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("register.protocolDateHourLabel")}
            >
              <span>{formatDateToDisplayWithHour(reportData.registerAt)}</span>
            </LabelField>
          </Col>
          <Row>
            <Col md={24} lg={12} className="gx-p-0">
              <LabelField
                labelWidth={labelSize}
                label={translation("register.protocolLabel")}
              >
                <span>{reportData.protocol}</span>
              </LabelField>
            </Col>
          </Row>
          {reportData.protocols &&
            reportData.protocols.map((item, index) => {
              return (
                <Row key={index}>
                  <Col md={24} className="gx-p-0">
                    <LabelField
                      labelWidth={labelSize}
                      label={translation(
                        `register.protocolNumberLabel.${formatProtocolType(
                          item.type
                        )}`
                      )}
                    >
                      <span>{item.registerNumber}</span>
                    </LabelField>
                  </Col>
                </Row>
              );
            })}
          {reportData.validation === "INVALID" && (
            <Row align="middle" justify="center">
              <h5 className="gx-m-0 gx-py-2">
                Sua carga foi averbada após o embarque e pode não estar coberta
                pelo seguro
              </h5>
            </Row>
          )}
        </BorderCard>
      </Row>
    </>
  );
};

export default ReportViewer;
