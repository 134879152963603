import React from "react";
// custom styles
import "./styles.less";

const logo = require(`../../assets/images/${process.env.REACT_APP_BRAND}_brand.png`);

const SidebarBrand = () => {
  return (
    <div className="sidebar-brand">
      <img src={logo.default} alt="logo" />
    </div>
  );
};

export default SidebarBrand;
