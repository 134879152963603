import React, { useEffect } from "react";
// router
import { Link } from "react-router-dom";
// translations
import { useTranslation } from "react-i18next";
// antd
import { Row, Spin, Upload, message, Col } from "antd";
import { InboxOutlined } from "@ant-design/icons";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStep,
  setChangedRegisterData,
  saveRegisterData,
  updateStepper
} from "../../../store/slices/sections/register";
// upload
import { uploadConfig } from "../../../util/Upload";
import { setLoading } from "../../../store/slices/common";
// components
import { FormRadioGroup, FormWrapper, LabelField } from "../../../components";
// custom hook
import { useRedirect } from "../../../hooks";
// paths
import { PATH_REGISTER } from "../../../routes/paths";
// custom styles
import "./styles.less";
// enums
import { documentTypesEnum } from "../../../util/Enums";
// constants
import { documentTypes, registerSteps } from "../../../util/Options";

const { Dragger } = Upload;

const RegisterSection = () => {
  const {
    register: {
      registerData: { documentType }
    },
    common: { loading }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const { redirect, fullRoute } = useRedirect();

  const url = "/averbamais-engine/v1/register/";
  const requestType = "POST";

  const onValuesChanged = (values) => {
    dispatch(setChangedRegisterData(values));
  };

  const handleSuccessUpload = (res) => {
    message.success(translation("register.upload.successMessage"));
    dispatch(saveRegisterData(res)).then((data) => {
      dispatch(setLoading(false));
      redirect(`${PATH_REGISTER.root}/${data.id}`);
    });
  };

  const handleErrorUpload = (error) => {
    message.error(
      error.response.data.message || translation("register.upload.errorMessage")
    );
    dispatch(setLoading(false));
  };

  const handleProgress = () => {
    message.info(translation("register.upload.progressMessage"));
  };

  const handleStartUpload = () => {
    dispatch(setLoading(true));
  };

  useEffect(() => {
    dispatch(setCurrentStep(0));
    return () => {
      dispatch(updateStepper(registerSteps.register));
    };
  }, [dispatch]);

  return (
    <div className="registerSection">
      <FormWrapper
        title={translation("register.pageTitle")}
        onValuesChange={onValuesChanged}
      >
        <Row align="middle" className="gx-mt-3">
          <LabelField
            labelWidth={170}
            vertical
            label={
              <span className="documentType-label">
                {translation("register.documentTypeLabel")}
              </span>
            }
          >
            <FormRadioGroup
              name="documentType"
              options={documentTypes}
              value={
                documentType === documentTypesEnum.OTHERS
                  ? documentTypesEnum.CTE
                  : documentType
              }
            />
          </LabelField>
        </Row>
        <Row type="flex" align="middle">
          <Col sm={24} md={24} className="gx-p-0">
            <Spin spinning={loading}>
              <Dragger
                action={`${url}${documentType}`}
                {...uploadConfig(requestType, null, handleProgress)}
                showUploadList={false}
                onSuccess={handleSuccessUpload}
                onError={handleErrorUpload}
                onStart={handleStartUpload}
              >
                <h5 className="ant-upload-text">
                  {translation("register.attachDocumentButton")}
                </h5>
                <InboxOutlined className="ant-upload-drag-icon" />
              </Dragger>
            </Spin>
          </Col>
        </Row>
        {/* <Row type="flex" align="middle" className="registerManually-label">
          <h5>
            {translation("register.registerManuallyLabel.startText")}&nbsp;
            <Link to={fullRoute(PATH_REGISTER.others)}>
              {translation("register.registerManuallyLabel.clickableText")}
            </Link>
            &nbsp;
            {translation("register.registerManuallyLabel.endText")}
          </h5>
        </Row> */}
      </FormWrapper>
    </div>
  );
};

export default RegisterSection;
