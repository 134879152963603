import React, { useState, useEffect } from "react";
import { Row } from "antd";
// translation hook
import { useTranslation } from "react-i18next";
// custom hooks
import { usePasswordChecker } from "../../hooks";
// components
import {
  FormWrapper,
  FormPassword,
  CheckIndicator,
  ActionButton,
  LabelField
} from "../index";

const UpdatePasswordForm = ({ loading, onSubmit, onChange, placeholder }) => {
  const [fieldData, setFieldData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [passwordMatch, setPasswordMatch] = useState(false);

  const { t: translation } = useTranslation();
  const [isOK, StrenghtIndicator, checkPassword] = usePasswordChecker();

  const onValuesChanged = (data) => {
    Object.keys(data).forEach((item) => {
      setFieldData({ ...fieldData, [item]: data[item] });
    });
    if (onChange) onChange();
  };

  const handleSubmit = (data) => {
    const { oldPassword, newPassword, confirmPassword } = data;
    onSubmit({
      oldPassword: oldPassword.trim(),
      newPassword: newPassword.trim(),
      confirmPassword: confirmPassword.trim()
    });
  };

  useEffect(() => {
    const { newPassword, confirmPassword } = fieldData;
    checkPassword(newPassword);
    if (newPassword.length > 0 && confirmPassword.length > 0)
      setPasswordMatch(newPassword === confirmPassword);
  }, [fieldData, checkPassword]);

  return (
    <FormWrapper
      title={translation("auth.updatePassword.headerTitle")}
      subtitle={translation("auth.updatePassword.headerMessage")}
      onSubmit={handleSubmit}
      onValuesChange={onValuesChanged}
      dataReference={fieldData}
    >
      <LabelField
        label={translation("auth.updatePassword.oldPasswordLabel")}
        vertical
      >
        <FormPassword
          name="oldPassword"
          className="gx-mb-3"
          placeholder={placeholder}
          iconRender={(visible) =>
            visible
              ? translation("auth.signIn.hidePasswordLabel")
              : translation("auth.signIn.showPasswordLabel")
          }
          rules={[
            {
              required: true,
              message: `${translation(
                "auth.updatePassword.oldPasswordRequiredMessage"
              )}`
            }
          ]}
        />
      </LabelField>
      <LabelField
        label={translation("auth.updatePassword.newPasswordLabel")}
        vertical
      >
        <FormPassword
          name="newPassword"
          className="gx-mb-1"
          placeholder={translation(
            "auth.updatePassword.newPasswordPlaceholder"
          )}
          iconRender={(visible) =>
            visible
              ? translation("auth.signIn.hidePasswordLabel")
              : translation("auth.signIn.showPasswordLabel")
          }
        />
      </LabelField>
      <Row className="gx-mb-3">
        <StrenghtIndicator />
      </Row>
      <LabelField
        label={translation("auth.updatePassword.confirmPasswordLabel")}
        vertical
      >
        <FormPassword
          name="confirmPassword"
          className="gx-mb-1"
          placeholder={translation(
            "auth.updatePassword.confirmPasswordPlaceholder"
          )}
          iconRender={(visible) =>
            visible
              ? translation("auth.signIn.hidePasswordLabel")
              : translation("auth.signIn.showPasswordLabel")
          }
        />
      </LabelField>
      <Row className="gx-mb-3">
        <CheckIndicator
          label={translation("auth.updatePassword.passwordMatchLabel")}
          value={passwordMatch}
        />
      </Row>
      <Row align="middle" justify="center">
        <ActionButton
          disabled={!isOK || !passwordMatch}
          loading={loading}
          type="primary"
          htmlType="submit"
          className="gx-mb-0"
        >
          {translation("auth.updatePassword.sendButton")}
        </ActionButton>
      </Row>
    </FormWrapper>
  );
};

export default UpdatePasswordForm;
