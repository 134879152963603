// redux
import { useSelector } from "react-redux";
// router
import { useHistory } from "react-router-dom";
// paths
import { PATH_ROOTS } from "../../routes/paths";

const useRedirect = () => {
  const { userType } = useSelector((state) => state.auth);
  const history = useHistory();
  return {
    redirect: (path) => history.push(`${PATH_ROOTS[userType]}${path}`),
    fullRoute: (path) => `${PATH_ROOTS[userType]}${path}`
  };
};

export default useRedirect;
