import React from "react";
import { Input } from "antd";
import * as PropTypes from "prop-types";
// Custom Styles
import "./Styles.less";
// Others
import NumberFormat from "react-number-format";

function InputCurrency(props) {
  const {
    allowNegative,
    displayType,
    readOnly,
    prefix,
    customInput,
    decimalSeparator,
    decimalScale,
    fixedDecimalScale,
    thousandSeparator,
    onChange,
    value,
    suffix,
    ...otherProps
  } = props;

  function handleValueChange(values) {
    return onChange && onChange(values.floatValue);
  }

  function getValue() {
    if (displayType === "input") {
      if (typeof value === "object") return value && value.formattedValue;
      return value;
    }
    return value;
  }

  const updatedValue = getValue();

  return (
    <NumberFormat
      {...otherProps}
      value={updatedValue}
      prefix={`${prefix} `}
      suffix={suffix}
      displayType={displayType}
      readOnly={readOnly}
      customInput={customInput}
      allowNegative={allowNegative}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      onValueChange={handleValueChange}
    />
  );
}

InputCurrency.propTypes = {
  displayType: PropTypes.string,
  mask: PropTypes.string,
  prefix: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  customInput: PropTypes.any,
  decimalScale: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
  suffix: PropTypes.string
};

InputCurrency.defaultProps = {
  displayType: "input",
  prefix: "R$",
  customInput: Input,
  thousandSeparator: ".",
  decimalScale: 2,
  fixedDecimalScale: true,
  decimalSeparator: ",",
  allowNegative: false,
  readOnly: false
};

export default InputCurrency;
