import React from "react";
// custom styles
import "./styles.less";

const TagLabel = ({ children, type, className }) => {
  return (
    <span className={`tag ${type ? `tag-${type}` : ""} ${className}`}>
      {children}
    </span>
  );
};

export default TagLabel;
