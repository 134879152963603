import React, { useEffect } from "react";
// redux
import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
// components
import { Layout } from "antd";
import { PageLayoutCard, Navbar, Sidebar } from "../components";
import { userTypes } from "../util/Enums";
// paths
import {
  PATH_AUTH,
  PATH_HOME,
  PATH_USERS,
  PATH_PROFILE
} from "../routes/paths";
// custom hooks
import { useRedirect } from "../hooks";

const { Content } = Layout;

const WithSider = ({ children, title, previous, ...restProps }) => {
  const {
    common: { fetching },
    auth: { userType, user }
  } = useSelector((state) => state);
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const { fullRoute, redirect } = useRedirect();

  const brokerAllowedRoute = () => {
    return (
      path.includes(PATH_USERS.root) ||
      path.includes(PATH_PROFILE.root) ||
      path.includes(PATH_AUTH.updatePassword)
    );
  };
  const redirectBroker = () => {
    if (userType === userTypes.BROKER && !user.documentNumber) {
      if (!brokerAllowedRoute()) {
        return redirect(PATH_HOME.broker);
      }
    }
  };

  useEffect(() => {
    redirectBroker();
  }, []);
  return (
    <Layout className="gx-h-100">
      <Sidebar />
      <Layout className="site-layout">
        <Navbar />
        <Content className="gx-pr-0 gx-h-100 gx-overflow-auto">
          {location.pathname === fullRoute(PATH_AUTH.updatePassword) ? (
            <>{children}</>
          ) : (
            <PageLayoutCard
              title={title}
              previous={previous}
              spinning={fetching}
              {...restProps}
            >
              {children}
            </PageLayoutCard>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default WithSider;
