const path = (root, url) => `${root}${url}`;

const ROOTS_INSURER = "/seguradora";
const ROOTS_BROKER = "/corretor";
const ROOTS_CUSTOMER = "";

const ROOTS_HOME = "/painel";
const ROOTS_USERS = "/usuarios";
const ROOTS_AUTH = "/";
const ROOTS_POLICY = "/apolice";
const ROOTS_REGISTER = "/averbacao";
const ROOTS_PROFILE = "/perfil";
const ROOTS_COMPANY = "/empresa";
const ROOTS_REPORT = "/relatorio";
const ROOTS_CANCEL = "/cancelar";
const ROOTS_SINISTER = "/sinistro";

// paths -----------------------------------------------

export const PATH_ROOTS = {
  INSURER: ROOTS_INSURER,
  BROKER: ROOTS_BROKER,
  CUSTOMER: ROOTS_CUSTOMER
};

export const PATH_HOME = {
  root: ROOTS_HOME,
  broker: "/inicio"
};

export const PATH_USERS = {
  root: ROOTS_USERS,
  newUser: path(ROOTS_USERS, "/novo"),
  editUser: path(ROOTS_USERS, "/:email/:documentNumber")
};

export const PATH_AUTH = {
  login: ROOTS_AUTH,
  forgotPassword: "/esqueci-senha",
  updatePassword: "/atualizar-senha",
  changePassword: "/alterar-senha"
};

export const PATH_POLICY = {
  root: ROOTS_POLICY,
  policyItem: path(ROOTS_POLICY, "/:id")
};

export const PATH_REGISTER = {
  root: ROOTS_REGISTER,
  others: path(ROOTS_REGISTER, "/outros"),
  confirm: path(ROOTS_REGISTER, "/:id"),
  cancel: path(ROOTS_REGISTER, `/:id${ROOTS_CANCEL}`),
  protocol: path(ROOTS_REGISTER, "/:id/protocolo")
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE
};

export const PATH_COMPANY = {
  root: ROOTS_COMPANY
};

export const PATH_CONFIRM = {
  root: "/confirmar"
};

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  details: path(ROOTS_REPORT, "/:id"),
  cancel: path(ROOTS_REPORT, `/:id${ROOTS_CANCEL}`),
  confirm: path(ROOTS_REPORT, `${PATH_CONFIRM.root}/:id`)
};

export const PATH_CANCEL = {
  root: ROOTS_CANCEL,
  manually: path(ROOTS_CANCEL, "/outros/:id"),
  confirm: path(ROOTS_CANCEL, "/:id"),
  protocol: path(ROOTS_CANCEL, "/:id/protocolo")
};

export const PATH_SINISTER = {
  root: ROOTS_SINISTER
};
