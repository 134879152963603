import React from "react";
// propo types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Modal, Row, Col, Input, Button, Table, Spin } from "antd";
// components
import NumberFormat from "react-number-format";
import FormMaskedInput from "../FormMaskedInput";
// amsks
import { CNPJ_MASK_STRING, COMPANY } from "../../util/Masks";
// formatters
import { formatDateToDisplayWithHour } from "../../util/formatters";

const InsuredSearchModal = ({
  visible,
  loading,
  companyList = [],
  onChange,
  onCancel,
  onSubmit,
  onSelect
}) => {
  const { t: translation } = useTranslation();

  const handleChange = (field, value) => onChange && onChange(field, value);
  const handleCancelModal = () => onCancel && onCancel();
  const handleSubmit = () => onSubmit && onSubmit();
  const handleSelect = (selected) => onSelect && onSelect(selected);

  const columns = [
    {
      title: translation("insureSearchModal.tableHeader.documentNumberLabel"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: "20%",
      render: (text) => (
        <NumberFormat displayType="text" value={text} format={COMPANY} />
      )
    },
    {
      title: translation("insureSearchModal.tableHeader.companyNameLabel"),
      dataIndex: "name",
      key: "name"
    },
    {
      title: translation("insureSearchModal.tableHeader.lastAccessLabel"),
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      render: (text) =>
        formatDateToDisplayWithHour(text) ||
        translation("insureSearchModal.table.neverAccessed")
    },
    // {
    //   title: translation(
    //     "insureSearchModal.tableHeader.registeredLast30DaysLabel"
    //   ),
    //   dataIndex: "registeredLast30Days",
    //   key: "registeredLast30Days",
    //   render: (text) => (
    //     <NumberFormat displayType="text" value={text} format={COMPANY} />
    //   )
    // },
    {
      title: "",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <Button
          type="link"
          size="small"
          className="gx-p-0 gx-m-0"
          onClick={() => handleSelect(text)}
        >
          {translation("insureSearchModal.table.accessLinkLabel")}
        </Button>
      )
    }
  ];

  const getFormattedData = () => {
    const formattedData = [];
    companyList.forEach((item) => {
      formattedData.push({
        documentNumber: item.documentNumber,
        name: item.name,
        lastLoginAt: item.lastLoginAt,
        // registeredLast30Days: item.registeredLast30Days,
        link: item.documentNumber
      });
    });
    return formattedData;
  };

  return (
    <Modal
      destroyOnClose
      maskClosable
      width={834}
      visible={visible}
      footer={null}
      onCancel={handleCancelModal}
    >
      <Spin spinning={loading}>
        <h3 className="gx-font-weight-bold">
          {translation("insureSearchModal.searchTitle")}
        </h3>
        <Row className="gx-m-0" align="middle">
          <Col
            sm={24}
            md={4}
            className="gx-p-0 gx-d-flex gx-justify-content-end"
          >
            <span className="gx-d-block gx-mb-20">
              {translation("insureSearchModal.documentNumberLabel")}
            </span>
          </Col>
          <Col sm={24} md={10} className="gx-m-0">
            <FormMaskedInput
              mask={CNPJ_MASK_STRING}
              placeholder={translation(
                "insureSearchModal.documentNumberPlaceholder"
              )}
              onChange={(e) => handleChange("documentNumber", e)}
            />
          </Col>
        </Row>
        {/* <Row className="gx-mb-3" align="middle">
          <Col
            sm={24}
            md={4}
            className="gx-p-0 gx-d-flex gx-justify-content-end"
          >
            <span className="gx-d-block gx-mb-10">
              {translation("insureSearchModal.companyNameLabel")}
            </span>
          </Col>
          <Col sm={24} md={10} className="gx-m-0">
            <Input
              type="text"
              placeholder={translation(
                "insureSearchModal.companyNamePlaceholder"
              )}
              className="gx-mb-10"
              onChange={(e) => handleChange("companyName", e)}
            />
          </Col>
        </Row> */}
        <Row justify="center">
          <Button type="primary" onClick={handleSubmit}>
            {translation("insureSearchModal.searchButton")}
          </Button>
        </Row>
        <h3 className="gx-font-weight-bold">
          {translation("insureSearchModal.insuredTitle")}
        </h3>
        <Table
          size="small"
          columns={columns}
          dataSource={getFormattedData()}
          pagination={{ pageSize: 3 }}
        />
      </Spin>
    </Modal>
  );
};

InsuredSearchModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  companyList: PropTypes.array,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSelect: PropTypes.func
};

export default InsuredSearchModal;
