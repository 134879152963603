import React from "react";
import "./styles.less";
import { LoadingOutlined } from "@ant-design/icons";

const IconButton = ({ icon, label, loading, ...props }) => {
  return (
    <button className="iconButton" {...props}>
      {loading ? <LoadingOutlined spin /> : icon}
      {label}
    </button>
  );
};

export default IconButton;
