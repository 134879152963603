import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const InputSelect = ({ options, placeholder, ...restProps }) => {
  const { t: translation } = useTranslation();

  return (
    <Select
      placeholder={placeholder}
      {...restProps}
      notFoundContent={
        <span>{translation("sideba.companySelect.noDataLabel")}</span>
      }
    >
      {options?.map((item, index) => (
        <Option key={index} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

InputSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string
};

export default InputSelect;
