import i18n from "../translation/i18n";
import { registerRangeEnum, registerStatusEnum } from "./Enums";

export const typeOptions = [
  { value: "RCTRC", label: i18n.t("constants.RCTRC") },
  { value: "RCFDC", label: i18n.t("constants.RCFDC") }
];

export const insurerOptions = [
  { value: "EZZE", label: "Ezze seguros" },
  { value: "ARGO_SEGUROS", label: "Argo seguros" },
  { value: "BERKLEY", label: "Berkley seguros" },
  { value: "BMG", label: "BMG seguros" },
  { value: "CAIXA", label: "Caixa seguros" },
  { value: "CHUBB", label: "Chubb seguros" },
  { value: "JNS", label: "JNS seguros" },
  { value: "JUNTO", label: "Junto seguros" },
  { value: "LIBERTY", label: "Liberty seguros" },
  { value: "PORTO_SEGURO", label: "Porto seguros" },
  { value: "SOMPO", label: "Sompo seguro seguros" },
  { value: "SURA", label: "Sura seguros" },
  { value: "TOKIO_MARINE", label: "Tokio marine seguros" }
];

export const registerRange = [
  { value: registerRangeEnum.today, label: "Averbados hoje" },
  { value: registerRangeEnum.month, label: "Averbados no mês atual" },
  { value: registerRangeEnum.lastSevenDays, label: "Nos últimos 7 dias" },
  { value: registerRangeEnum.lastThirtyDays, label: "Nos últimos 30 dias" },
  { value: registerRangeEnum.selectPeriod, label: "Selecionar período" }
];

export const registerStatus = [
  { value: registerStatusEnum.all, label: i18n.t("register.status.all") },
  {
    value: registerStatusEnum.pending,
    label: i18n.t("register.status.pending")
  },
  // { value: registerStatusEnum.free, label: "Isenta" },
  {
    value: registerStatusEnum.cancelled,
    label: i18n.t("register.status.cancelled")
  },
  {
    value: registerStatusEnum.registered,
    label: i18n.t("register.status.registered")
  },
  {
    value: registerStatusEnum.cancel_in_analysis,
    label: i18n.t("register.status.cancel_in_analysis")
  },
  {
    value: registerStatusEnum.informed,
    label: i18n.t("register.status.informed")
  },
  {
    value: registerStatusEnum.cancel_pending,
    label: i18n.t("register.status.cancel_pending")
  }
];

export const transportModal = [
  { value: "ROAD_MODAL", label: i18n.t("register.manually.roadModalLabel") },
  {
    value: "RAIL_MODAL",
    label: i18n.t("register.manually.railModalLabel"),
    disabled: true
  },
  {
    value: "AIR_MODAL",
    label: i18n.t("register.manually.airModalLabel"),
    disabled: true
  },
  {
    value: "MARINE_MODAL",
    label: i18n.t("register.manually.marineModalLabel"),
    disabled: true
  }
];

export const documentTypes = [
  { value: "CTE", label: "CTe" },
  { value: "NFE", label: "NFe" },
  // { value: "NFSE", label: "NFSe" },
  { value: "MDFE", label: "MDFe" }
];

export const registerSteps = {
  register: [
    { title: i18n.t("register.stepper.attachDocument") },
    { title: i18n.t("register.stepper.confirm") },
    { title: i18n.t("register.stepper.registered") }
  ],
  manually: [
    { title: i18n.t("register.stepper.manually") },
    { title: i18n.t("register.stepper.typeData") },
    { title: i18n.t("register.stepper.registered") }
  ]
};

export const userPermissions = [
  { value: "admin", label: i18n.t("user.permissions.admin") },
  { value: "register", label: i18n.t("user.permissions.register") },
  { value: "view", label: i18n.t("user.permissions.view") }
];

export const passwordStrengthOptions = [
  {
    label: i18n.t("auth.updatePassword.atLeastOneUppercaseLetter"),
    value: false,
    regex: new RegExp("(?=.*[A-Z])")
  },
  {
    label: i18n.t("auth.updatePassword.atLeastOneLowercaseLetter"),
    value: false,
    regex: new RegExp("(?=.*[a-z])")
  },
  {
    label: i18n.t("auth.updatePassword.atLeastOneSpecialCharacter"),
    value: false,
    regex: new RegExp("(?=.*[^A-Za-z0-9])")
  },
  {
    label: i18n.t("auth.updatePassword.atLeastOneDigit"),
    value: false,
    regex: new RegExp("(?=.*[0-9])")
  },
  {
    label: i18n.t("auth.updatePassword.atLeast8CharactersLong"),
    value: false,
    regex: new RegExp("(?=.{8,})")
  }
];

export const cancelSteps = [
  {
    title: i18n.t("cancel.steps.attachDocument")
  },
  {
    title: i18n.t("cancel.steps.verifyData")
  },
  {
    title: i18n.t("cancel.steps.cancelSucces")
  }
];

export const states = [
  { value: "", label: "Selecione o estado" },
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" }
];
