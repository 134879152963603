import React, { useState, useEffect } from "react";
// redux
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
// translation hook
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import {
  fetchCompanyData,
  saveCompanyData
} from "../../store/slices/sections/company";
// mask
import {
  COMPANY,
  ZIPCODE,
  CNPJ_MASK_STRING,
  PHONEMASK_NUMBER
} from "../../util/Masks";
// components
import {
  FormWrapper,
  FieldList,
  ActionButton,
  LabelField,
  PageHeader
} from "../../components";
// custom styles
import "./styles.less";
// helper
import { arrayIsEmpty } from "../../util/Helpers";
// formatters
import { removeMask } from "../../util/formatters";

const Company = ({ title, previous }) => {
  const [changed, setChanged] = useState(false);
  const {
    company: { companyData },
    common: { loading }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const onSubmit = (values) => {
    values = {
      ...values,
      phones: values.phones.map((item) => removeMask(item)),
      linkedDocumentNumber: values.linkedDocumentNumber.map((item) =>
        removeMask(item)
      )
    };
    dispatch(saveCompanyData(values));
    setChanged(false);
  };

  const onValuesChanged = () => {
    setChanged(true);
  };

  const setInitialData = (data) => ({
    ...data,
    emails: arrayIsEmpty(data.emails) ? [""] : data.emails,
    phones: arrayIsEmpty(data.phones) ? [""] : data.phones,
    linkedDocumentNumber: arrayIsEmpty(data.linkedDocumentNumber)
      ? [""]
      : data.linkedDocumentNumber
  });

  const labelSize = 115;
  useEffect(() => {
    dispatch(fetchCompanyData());
  }, [dispatch]);

  return (
    <>
      <PageHeader title={title} previous={previous} />
      <FormWrapper
        title={translation("company.sectionHeader")}
        onSubmit={onSubmit}
        onValuesChange={onValuesChanged}
        dataReference={setInitialData(companyData)}
      >
        <Row align="middle" className="gx-mb-2">
          <Col xs={24} md={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.documentNumber")}
            >
              <NumberFormat
                displayType="text"
                value={companyData.documentNumber}
                format={COMPANY}
              />
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-2">
          <Col xs={24} md={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.businessName")}
            >
              <span>{companyData.name}</span>
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-2">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.address")}
            >
              <span>{companyData.address}</span>
            </LabelField>
          </Col>
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.additionalAddress")}
            >
              <span>
                {companyData.additionalData ? companyData.additionalData : ""}
              </span>
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-2">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.city")}
            >
              <span>{companyData.city}</span>
            </LabelField>
          </Col>
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.state")}
            >
              <span>{companyData.state}</span>
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-2">
          <LabelField
            labelWidth={labelSize}
            label={translation("company.zipCode")}
          >
            <NumberFormat
              displayType="text"
              value={companyData.zipCode}
              format={ZIPCODE}
            />
          </LabelField>
        </Row>
        <Row type="flex" align="top" className="gx-mb-2">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.email")}
              className="companyFieldList"
            >
              <FieldList name="emails" />
            </LabelField>
          </Col>

          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.phone")}
              className="companyFieldList"
            >
              <FieldList name="phones" mask={PHONEMASK_NUMBER} />
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="top" className="gx-mb-2">
          <Col xs={24} md={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("company.linkedDocumentNumber")}
              className="companyFieldList"
            >
              <FieldList name="linkedDocumentNumber" mask={CNPJ_MASK_STRING} />
            </LabelField>
          </Col>
        </Row>
        <Row type="flex" align="center" className="gx-px-3">
          <ActionButton
            size="middle"
            className="gx-mb-0"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {translation("company.saveButton")}
          </ActionButton>
        </Row>
      </FormWrapper>
    </>
  );
};

export default Company;
