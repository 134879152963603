import axios from "axios";
import { reduxStore } from "../index";
import { removeToken, fetchRefreshToken } from "../store/slices/auth";

const instanceConfig = {
  baseURL:
    `${window._env_.REACT_APP_API_GATEWAY_URL}` ||
    "https://staging-api.mutuus.net",
  headers: {
    "Content-Type": "application/json"
  }
};
export const api = axios.create(instanceConfig);

api.interceptors.request.use(
  (config) => {
    if (config.url === "/auth/v1/authenticate" && config.method === "put") {
      delete config.headers.Authorization;
      return config;
    }
    if (
      !(
        config.url.endsWith("/authenticate") ||
        config.url.endsWith("/forgot-password")
      )
    ) {
      const userToken = localStorage.getItem("token");
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    if (error.response) {
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          return reduxStore.dispatch(fetchRefreshToken()).then((res) => {
            api.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
            return api.request(config);
          });
        }
        if (error.response.status === 403) {
          return reduxStore.dispatch(removeToken());
        }
      }
    }
    return Promise.reject(error);
  }
);
