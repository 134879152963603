import React from "react";
import InputMask from "react-input-mask";
import { Form } from "antd";
// custom styles
import "./styles.less";

const InputMaskWrapper = (props) => {
  const { value, onChange, mask, prefix, placeholder, restProps } = props;

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <span className="ant-input-affix-wrapper">
      {prefix ? <span className="ant-input-prefix">{prefix}</span> : null}
      <InputMask
        mask={mask}
        placeholder={placeholder}
        className="ant-input"
        value={value}
        onChange={handleChange}
        {...restProps}
      />
    </span>
  );
};

const FormMaskedInput = (props) => {
  const {
    label,
    name,
    placeholder,
    mask,
    rules,
    prefix,
    onChange,
    ...restProps
  } = props;
  return (
    <Form.Item
      label={label}
      name={name}
      className="formMaskedInput"
      rules={rules}
    >
      <InputMaskWrapper
        placeholder={placeholder}
        mask={mask}
        prefix={prefix}
        restProps={restProps}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FormMaskedInput;
