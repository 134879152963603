import React from "react";
import { Form, Radio } from "antd";

const FormRadioButton = (props) => {
  const { options, label, name, value, ...restProps } = props;
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={value}
      className="gx-p-1"
    >
      <Radio.Group options={options} {...restProps} />
    </Form.Item>
  );
};

export default FormRadioButton;
