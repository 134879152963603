export const getErrorMessage = (field, errors) => {
  const error =
    errors && Array.isArray(errors)
      ? errors.find((item) => item.field === field)
      : null;
  return error ? error.message : null;
};

export const clearError = (field, errors) => {
  const fieldChanged = Object?.keys(field);
  return errors && errors.filter((item) => item.field !== fieldChanged[0]);
};
