import i18n from "../translation/i18n";

export const registerRangeEnum = {
  today: "today",
  month: "month",
  lastSevenDays: "lastSevenDays",
  lastThirtyDays: "lastThirtyDays",
  selectPeriod: "selectPeriod"
};

export const registerStatusEnum = {
  all: "",
  pending: "PENDING",
  free: "free",
  cancelled: "CANCELED",
  registered: "CONFIRMED",
  cancel_in_analysis: "CANCEL_IN_ANALYSIS",
  informed: "INFORMED",
  cancel_pending: "CANCEL_PENDING"
};

export const userStatus = {
  OK: "OK",
  NEED_UPDATE_PASSWORD: "NEED_UPDATE_PASSWORD",
  BLOCKED: "BLOCKED",
  DELETED: "DELETED"
};

export const userActions = {
  BLOCK: "BLOCK",
  UNBLOCK: "UNBLOCK",
  DELETE: "DELETE",
  CLOSE: "CLOSE"
};

export const userTypes = {
  INSURER: "INSURER",
  BROKER: "BROKER",
  CUSTOMER: "CUSTOMER"
};

export const documentTypesEnum = {
  CTE: "CTE",
  NFE: "NFE",
  NFSE: "NFSE",
  MDFE: "MDFE",
  OTHERS: "OTHERS"
};

export const brandTypes = {
  AVERBAMAIS: "averbamais",
  MUTUUS: "mutuus"
};

export const insurers = {
  SURA: {
    phone: "0800-704-7470"
  },
  EZZE: {
    phone: "0800-770-6607"
  },
  TOKIO: {
    phone: "0800-723-0108"
  },
  LIBERTY: {
    phone: "Capitais 4004-5423 - Demais  0800-709-5423"
  },
  PORTO: {
    phone: "SP  (11) 3366-3380 - Demais 0800-727-2755"
  },
  CHUBB: {
    phone: "0800-777-9077"
  }
};

export const uploadDocumentForm = {
  EMAIL: i18n.t("registerViewer.uploadByEmail"),
  UPLOAD: i18n.t("registerViewer.uploadByWebPlatform"),
  WEBSITE: i18n.t("registerViewer.uploadByWebPlatform")
};

export const policyTypes = {
  RCTRC: "RCT-RC",
  RCFDC: "RCF-DC",
  TN: "TN",
  RCTRVI: "RCTR-VI"
};
