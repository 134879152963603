import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row } from "antd";
import {
  setCurrentStep,
  fetchRegisterById,
  cleanRegisterData
} from "../../../store/slices/sections/register";
import { ActionButton } from "../../../components";
import { useRedirect } from "../../../hooks";
import { PATH_REGISTER, PATH_HOME } from "../../../routes/paths";
import { formatProtocolType } from "../../../util/formatters";
import "./styles.less";
import ReportViewer from "../../../components/ReportViewer";

const RegisterProtocol = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { redirect } = useRedirect();
  const { t: translation } = useTranslation();
  const {
    register: { registerData },
    common: { fetching }
  } = useSelector((state) => state);

  const fetchRegisterAndRedirect = () => {
    return dispatch(fetchRegisterById(id)).catch(() =>
      redirect(PATH_REGISTER.root)
    );
  };
  const labelSize = 185;

  useEffect(() => {
    dispatch(setCurrentStep(2));
    fetchRegisterAndRedirect();
  }, [dispatch]);

  const cleanRegisterandRedirect = () => {
    dispatch(cleanRegisterData());
    redirect(PATH_HOME.root);
  };

  const showDynamicProtocolType = () => {
    const types = registerData.protocols?.map((item) =>
      formatProtocolType(item.type)
    );
    // TODO: separador `e` temporário, quando a quantidade de types aumentar, trocar o separador
    return types?.join(" e ");
  };
  return (
    !fetching && (
      <div className="gx-py-4">
        <ReportViewer reportData={registerData} />
        <Row justify="center">
          <ActionButton
            type="primary"
            size="large"
            onClick={cleanRegisterandRedirect}
          >
            {translation("register.protocolFinishButtonLabel")}
          </ActionButton>
        </Row>
      </div>
    )
  );
};

export default RegisterProtocol;
