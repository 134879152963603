import React from "react";
import { Form } from "antd";
import LocationInput from "../LocationInput";

const FormLocationInput = ({
  label,
  name,
  placeholder,
  error,
  rules,
  ...restProps
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      help={error}
      validateStatus={error && "error"}
      className="formInput gx-p-1"
      rules={rules}
    >
      <LocationInput placeholder={placeholder} {...restProps} />
    </Form.Item>
  );
};

export default FormLocationInput;
