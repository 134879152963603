import { createSlice } from "@reduxjs/toolkit";

const routesParamsSlice = createSlice({
  name: "routesParams",
  initialState: {
    paramsData: {
      origin: localStorage.getItem("origin") || ""
    }
  },
  reducers: {
    appendParamsData(state, action) {
      state.paramsData = {
        ...state.paramsData,
        ...action.payload
      };
      if (action?.payload?.origin) {
        localStorage.setItem("origin", action?.payload?.origin);
      }
    },
    removeOrigin: (state) => {
      if (state.paramsData?.origin) {
        state.paramsData.origin = "";
      }
      localStorage.removeItem("origin");
    }
  }
});

export const { appendParamsData, removeOrigin } = routesParamsSlice.actions;

export default routesParamsSlice.reducer;
