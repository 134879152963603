export const COMPANY = "##.###.###/####-##";
export const ZIPCODE = "##.###-###";
export const DATEMASK = "DD/MM/YYYY";
export const DATEHOURMASK = "DD/MM/YYYY-HH:mm";
export const ISODATE = "YYYY-MM-DD";
export const ISODATETIME = "YYYY-MM-DDTHH:mm:ss";
export const CNPJ_MASK_STRING = "99.999.999/9999-99";
export const CNPJMASK = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/
];

export const PHONEMASK = [
  "(",
  /\d/,
  /\d/,
  ")",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const PHONEMASK_STRING = "(##) #####-####";
export const PHONEMASK_NUMBER = "(99) 99999-9999";
