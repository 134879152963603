import React from "react";
import { Steps } from "antd";

const Stepper = ({ steps, current, ...restProps }) => {
  return (
    <Steps current={current} {...restProps}>
      {steps.map((item, index) => (
        <Steps.Step key={index} {...item} />
      ))}
    </Steps>
  );
};

export default Stepper;
