import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./style.less";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  StopFilled,
  WarningFilled
} from "@ant-design/icons";

const PolicyStatusTag = ({ finalTermAt, cancelledAt }) => {
  const { t: translation } = useTranslation();

  if (cancelledAt) {
    return (
      <div className="policy-status-tag">
        <StopFilled style={{ color: "#FF665C" }} />
        <span className="tag-label">
          {translation("policy.status.cancelled")}
        </span>
      </div>
    );
  }
  if (moment(finalTermAt) - moment() < 0) {
    return (
      <div className="policy-status-tag">
        <CloseCircleFilled style={{ color: "#FF665C" }} />
        <span className="tag-label">
          {translation("policy.status.overdue")}
        </span>
      </div>
    );
  }
  if (moment(finalTermAt).diff(moment(), "days") <= 30) {
    return (
      <div className="policy-status-tag">
        <WarningFilled style={{ color: "#F9B226" }} />
        <span className="tag-label">
          {translation("policy.status.thirtyDaysToExpire")}
        </span>
      </div>
    );
  }
  if (moment(finalTermAt) - moment() >= 0) {
    return (
      <div className="policy-status-tag">
        <CheckCircleFilled style={{ color: "#91BF1D" }} />
        <span className="tag-label">
          {translation("policy.status.current")}
        </span>
      </div>
    );
  }
  return null;
};

PolicyStatusTag.propTypes = {
  finalTermAt: PropTypes.string,
  cancelledAt: PropTypes.string
};

export default PolicyStatusTag;
