import { createSlice } from "@reduxjs/toolkit";
import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE_SEMI_DARK
} from "../../../theme/constants/ThemeSetting";

export const settingSlice = createSlice({
  name: "settings",
  initialState: {
    navStyle: NAV_STYLE_FIXED,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_SEMI_DARK,
    themeColor: THEME_COLOR,

    isDirectionRTL: false,
    locale: {
      languageId: "english",
      locale: "en",
      name: "English",
      icon: "us"
    }
  },
  reducers: {
    themeType: (state, action) => {
      state.themeType = action.payload;
    },
    themeColor: (state, action) => {
      state.themeColor = action.payload;
    },
    navStyle: (state, action) => {
      state.navStyle = action.payload;
    },
    layoutType: (state, action) => {
      state.layoutType = action.payload;
    },
    switchLanguage: (state, action) => {
      state.switchLanguage = action.payload;
    }
  }
});

export const { themeType, themeColor, navStyle, layoutType, switchLanguage } =
  settingSlice.actions;

export default settingSlice.reducer;
