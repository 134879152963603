import moment from "moment";
import { ISODATE } from "../util/Masks";

export const datePeriods = {
  month: {
    startAt: moment().startOf("month").format(ISODATE),
    endAt: moment().endOf("month").format(ISODATE)
  },
  today: {
    startAt: moment().format(ISODATE),
    endAt: moment().format(ISODATE)
  },
  lastSevenDays: {
    startAt: moment().subtract(7, "d").format(ISODATE),
    endAt: moment().format(ISODATE)
  },
  lastThirtyDays: {
    startAt: moment().subtract(30, "d").format(ISODATE),
    endAt: moment().format(ISODATE)
  }
};

export const userOrigins = {
  CLIENT_PORTAL_APP: "CLIENT_PORTAL_APP"
};
