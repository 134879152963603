import React from "react";
import { Modal, Menu } from "antd";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/auth";
import { setCurrentMenuKeys } from "../../store/slices/common";

import { THEME_TYPE_LITE } from "../../theme/constants/ThemeSetting";
// styles
import "./styles.less";
// custom hook
import { useRedirect } from "../../hooks";
// paths
import { PATH_REGISTER, PATH_REPORT, PATH_CANCEL } from "../../routes/paths";

const { SubMenu } = Menu;
const rootSubmenuKeys = ["registration", "account"];

const ClientPortalSidebarContent = () => {
  const { themeType } = useSelector(({ settings }) => settings);
  const { t: translation } = useTranslation();
  const {
    common: { currentMenuKeys }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { fullRoute } = useRedirect();

  const handleLogout = () => {
    const { confirm } = Modal;
    const confirmLogoutModal = () => {
      confirm({
        title: translation("logout.confirmModalTitle"),
        onOk() {
          dispatch(logout());
        },
        onCancel() {
          return false;
        },
        okText: translation("logout.confirmModalOkButton"),
        cancelText: translation("logout.confirmModalCancelButton")
      });
    };
    confirmLogoutModal();
  };

  const onClick = (key) => {
    if (key) {
      dispatch(setCurrentMenuKeys(key.keyPath));
    }
  };

  const onOpenChange = (keys) => {
    if (currentMenuKeys) {
      const latestOpenKey = keys.find(
        (key) => currentMenuKeys.indexOf(key) === -1
      );
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        dispatch(setCurrentMenuKeys(keys));
      } else {
        dispatch(setCurrentMenuKeys(latestOpenKey ? [latestOpenKey] : []));
      }
    }
  };

  const clientPortalUrl = () => window._env_.PORTAL_APP_URL_ENV;
  const mutuusBlogUrl = () => window._env_.MUTUUS_BLOG_URL_ENV;
  const formsUrl = () => window._env_.FORMS_URL_ENV;

  const CLIENT_PORTAL_URL = clientPortalUrl();
  const MUTUUS_BLOG_URL = mutuusBlogUrl();
  const FORMS_URL = formsUrl();

  return (
    <>
      <Menu
        openKeys={currentMenuKeys || []}
        selectedKeys={currentMenuKeys || []}
        theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
        mode="inline"
        onClick={onClick}
        onOpenChange={onOpenChange}
      >
        <Menu.Item key="home">
          <a href={`${CLIENT_PORTAL_URL}`}>
            <span>{translation("sidebar.portalApp.initial")}</span>
          </a>
        </Menu.Item>

        <Menu.Item key="financial">
          <a href={`${CLIENT_PORTAL_URL}/financeiro`}>
            <span>{translation("sidebar.portalApp.financial")}</span>
          </a>
        </Menu.Item>

        <Menu.Item key="policy">
          <a href={`${CLIENT_PORTAL_URL}/apolices`}>
            <span>{translation("sidebar.portalApp.policy")}</span>
          </a>
        </Menu.Item>

        <SubMenu
          key="registration"
          title={translation("sidebar.registration")}
          className="sidebar-content-menu"
        >
          <Menu.Item key="register">
            <Link to={fullRoute(PATH_REGISTER.root)}>
              <span>{translation("sidebar.registration.register")}</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="report">
            <Link to={fullRoute(PATH_REPORT.root)}>
              <span>{translation("sidebar.registration.report")}</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="cancel">
            <Link to={fullRoute(PATH_CANCEL.root)}>
              <span>{translation("sidebar.registration.cancel")}</span>
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="sinister">
          <a href={`${CLIENT_PORTAL_URL}/sinistros`}>
            <span>{translation("sidebar.portalApp.sinister")}</span>
          </a>
        </Menu.Item>

        <Menu.Item key="orderRequest">
          <a href={`${CLIENT_PORTAL_URL}/pedidos-de-seguro`}>
            <span>{translation("sidebar.portalApp.orderRequest")}</span>
          </a>
        </Menu.Item>

        <Menu.Item key="blog">
          <a href={`${MUTUUS_BLOG_URL}`} target="_blank" rel="noreferrer">
            <span>{translation("sidebar.portalApp.blog")}</span>
          </a>
        </Menu.Item>

        <Menu.Item key="help">
          <a href={`${CLIENT_PORTAL_URL}/ajuda`}>
            <span>{translation("sidebar.portalApp.help")}</span>
          </a>
        </Menu.Item>

        <Menu.Item key="newOrder">
          <a href={`${FORMS_URL}`} target="_blank" rel="noreferrer">
            <span>{translation("sidebar.portalApp.newOrder")}</span>
          </a>
        </Menu.Item>
        <Menu.Item key="logout">
          <span onClick={handleLogout}>{translation("sidebar.logout")}</span>
        </Menu.Item>
      </Menu>
      <div className="contact-items-wrapper">
        <p>
          <span>{translation("sidebar.portalApp.whatsApp.label")}</span>
          <br />
          <span>{translation("sidebar.portalApp.whatsApp.value")}</span>
        </p>
        <p>
          <span>{translation("sidebar.portalApp.phoneNumber.label")}</span>
          <br />
          <span>{translation("sidebar.portalApp.phoneNumber.value")}</span>
        </p>
        <p>
          <span>{translation("sidebar.portalApp.email.label")}</span>
          <br />
          <span>{translation("sidebar.portalApp.email.value")}</span>
        </p>
      </div>
    </>
  );
};

ClientPortalSidebarContent.propTypes = {};
export default ClientPortalSidebarContent;
