import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  LabelField,
  InputSelect,
  InputCurrency,
  InputDate,
  PolicyStatusTag,
  FormField
} from "../../../../components";
import { formatDateToDisplay } from "../../../../util/formatters";
import { insurerOptions, typeOptions } from "../../../../util/Options";
import { getErrorMessage } from "../../../../util/Errors";

const EditPolicyView = ({
  data = {},
  errors = [],
  onBlock,
  onUnblock,
  onChange,
  onSubmit
}) => {
  const [enableFields, setEnableFields] = useState(false);
  const { t: translation } = useTranslation();
  const labelSize = 230;
  const labelStyles = {
    justifyContent: "flex-start",
    textAlign: "left"
  };

  const buttonStyles = {
    maxHeight: "36px",
    marginBottom: "0px",
    padding: "0 16px",
    fontSize: "16px",
    bordeRadius: "6px"
  };

  const handleUnblockPolicy = () => onUnblock && onUnblock();
  const handleBlockPolicy = () => onBlock && onBlock();
  const handleSubmit = () => onSubmit && onSubmit();
  const handleChange = (e) => onChange && onChange(e);

  return (
    <div style={{ maxWidth: "1200px" }}>
      {data?.finalTermAt ? (
        <Row align="middle" className="gx-mb-3">
          <PolicyStatusTag finalTermAt={data?.finalTermAt} />
        </Row>
      ) : null}
      <Row align="middle" className="gx-mb-2">
        <Col sm={24} md={4}>
          <span>{translation("editPolicyView.insurer.label")}</span>
        </Col>
        <Col sm={24} md={8}>
          <FormField error={getErrorMessage("insurer", errors)}>
            <InputSelect
              disabled
              name="insurer"
              className="gx-w-100"
              options={insurerOptions}
              placeholder={translation("editPolicyView.insurer.placeholde")}
              value={data?.insurer?.id || ""}
              onChange={handleChange}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4}>
          <span>{translation("editPolicyView.policyType.label")}</span>
        </Col>
        <Col sm={24} md={8}>
          <FormField error={getErrorMessage("type", errors)}>
            <InputSelect
              disabled
              name="type"
              className="gx-w-100"
              options={typeOptions}
              value={data?.type || ""}
              placeholder={translation("editPolicyView.policyType.placeholder")}
              onChange={handleChange}
            />
          </FormField>
        </Col>
      </Row>
      <Row align="middle" className="gx-mb-2">
        <Col sm={24} md={4}>
          <span>{translation("editPolicyView.policyNumber.label")}</span>
        </Col>
        <Col sm={24} md={8}>
          <FormField error={getErrorMessage("policyNumber", errors)}>
            <Input
              disabled={!enableFields}
              name="policyNumber"
              className="gx-w-100"
              value={data?.policyNumber}
              onChange={(e) =>
                handleChange({ name: "policyNumber", value: e.target.value })
              }
            />
          </FormField>
        </Col>
        <Col sm={24} md={4}>
          <span>{translation("editPolicyView.LMG.label")}</span>
        </Col>
        <Col sm={24} md={8}>
          <FormField error={getErrorMessage("maximumGuaranteeLimit", errors)}>
            <InputCurrency
              disabled={!enableFields}
              name="maximumGuaranteeLimit"
              className="gx-w-100"
              value={data?.maximumGuaranteeLimit}
              onChange={(e) =>
                handleChange({ name: "maximumGuaranteeLimit", value: e })
              }
            />
          </FormField>
        </Col>
      </Row>
      <Row align="middle" className="gx-mb-2">
        <Col sm={24} md={4}>
          <span>{translation("editPolicyView.startAt.label")}</span>
        </Col>
        <Col sm={24} md={8}>
          <FormField error={getErrorMessage("startAt", errors)}>
            <InputDate
              disabled={!enableFields}
              name="startAt"
              value={data.startAt ? moment(data.startAt) : ""}
              onChange={(e) =>
                handleChange({
                  name: "startAt",
                  value: moment(e).isValid()
                    ? moment(e).format("YYYY-MM-DD")
                    : null
                })
              }
            />
          </FormField>
        </Col>
        <Col sm={24} md={4}>
          <span>{translation("editPolicyView.endAt.label")}</span>
        </Col>
        <Col sm={24} md={8}>
          <FormField error={getErrorMessage("startAt", errors)}>
            <InputDate
              disabled={!enableFields}
              name="endAt"
              value={data?.endAt ? moment(data.endAt) : ""}
              onChange={(e) =>
                handleChange({
                  name: "endAt",
                  value: moment(e).isValid()
                    ? moment(e).format("YYYY-MM-DD")
                    : null
                })
              }
            />
          </FormField>
        </Col>
      </Row>
      {data.lockedAt && (
        <Row className="gx-mt-5">
          <Col>
            <LabelField
              labelWidth={labelSize}
              labelStyles={labelStyles}
              label={translation("policy.blockedByLabel")}
            >
              <span>
                {data.lockedBy?.name}
                &nbsp; em &nbsp;
                {formatDateToDisplay(data.lockedAt)}
              </span>
            </LabelField>
          </Col>
        </Row>
      )}

      <Row justify="end" className="gx-mt-5">
        {data.lockedAt ? (
          <Button
            size="small"
            style={buttonStyles}
            onClick={handleUnblockPolicy}
          >
            {translation("policy.unblockPolicyPaymentButton")}
          </Button>
        ) : (
          <Button size="small" style={buttonStyles} onClick={handleBlockPolicy}>
            {translation("policy.blockPolicyPaymentButton")}
          </Button>
        )}
        {enableFields ? (
          <Button
            type="primary"
            size="small"
            style={buttonStyles}
            onClick={handleSubmit}
          >
            {translation("editPolicyView.saveButton.label")}
          </Button>
        ) : (
          <Button
            type="primary"
            size="small"
            style={buttonStyles}
            onClick={() => setEnableFields(true)}
          >
            {translation("editPolicyView.editButton.label")}
          </Button>
        )}
      </Row>
    </div>
  );
};

EditPolicyView.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
  onBlock: PropTypes.func,
  onUnblock: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default EditPolicyView;
