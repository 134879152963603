import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { api } from "../../../util/Api";
import { setLoading, setSuccess, setFetching } from "../common";
import i18n from "../../../translation/i18n";
// enums
import { userTypes } from "../../../util/Enums";
import { fetchCompanyData } from "../sections/company";
import { removeOrigin } from "../routesParams";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userToken: localStorage.getItem("token") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    expireAt: "",
    user: JSON.parse(localStorage.getItem("user")) || {},
    errors: [],
    userType: localStorage.getItem("userType") || userTypes.CUSTOMER,
    insurers: JSON.parse(localStorage.getItem("insurers")) || { insurers: [] }
  },
  reducers: {
    setToken: (state, action) => {
      state.userToken = action.payload;
      localStorage.setItem("token", action.payload);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", action.payload);
    },
    setExpire: (state, action) => {
      state.expireAt = action.payload;
    },
    removeToken: (state) => {
      state.userToken = "";
      state.refreshToken = "";
      state.expireAt = "";
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    },
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem(
        "user",
        JSON.stringify({
          documentNumber: action?.payload?.documentNumber,
          insuredDocumentNumber: action.payload.insuredDocumentNumber,
          email: action.payload.email,
          name: action.payload.name
        })
      );
    },
    setBroker: (state, action) => {
      localStorage.setItem(
        "broker",
        JSON.stringify({
          ...action.payload
        })
      );
    },
    setCustomer: (state, action) => {
      localStorage.setItem(
        "customer",
        JSON.stringify({
          ...action.payload
        })
      );
    },
    removeUser: (state) => {
      state.user = {};
      localStorage.removeItem("user");
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setRemember: (state, action) => {
      if (action.payload) localStorage.setItem("remember", true);
      else localStorage.setItem("remember", false);
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
      localStorage.setItem("userType", action.payload);
    },
    setInsurers: (state, action) => {
      state.insurers = {
        insurers: action.payload
      };
      localStorage.setItem("insurers", JSON.stringify(state.insurers));
    }
  }
});

export const {
  setToken,
  setRefreshToken,
  setExpire,
  removeToken,
  setUser,
  removeUser,
  setErrors,
  setRemember,
  setUserType,
  setInsurers,
  setBroker,
  setCustomer
} = authSlice.actions;

export const fetchBrokerInsuredSelection = (data) => (dispatch, getState) => {
  const {
    auth: { user }
  } = getState();
  dispatch(setFetching(true));
  const brokerData = JSON.parse(localStorage.getItem("broker"));
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${window._env_.REACT_APP_API_GATEWAY_URL}/auth/v1/broker/changeCompany/${data}`,
        {},
        {
          headers: { Authorization: `Bearer ${brokerData.token}` }
        }
      )
      .then((response) => {
        dispatch(setUser({ name: user?.name, ...response.data.user }));
        dispatch(setToken(response.data.token));
        dispatch(setRefreshToken(response.data.refreshToken));
        dispatch(setInsurers(response.data.insurers));
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        reject(error);
      })
      .finally(() => {
        dispatch(setFetching(false));
      });
  });
};

export const userSignIn = (data) => (dispatch, getState) => {
  const {
    auth: { userType }
  } = getState();
  dispatch(setLoading(true));
  api
    .post("auth/v1/authenticate", { ...data, userType })
    .then((response) => {
      dispatch(setRemember(data.remember));
      dispatch(setLoading(false));
      dispatch(setUser(response.data.user));
      if (userType === userTypes.CUSTOMER) {
        dispatch(setCustomer(response.data.user));
      } else if (userType === userTypes.BROKER) {
        dispatch(
          setBroker({
            ...response.data.user,
            token: response.data.token,
            refreshToken: response.data.refreshToken
          })
        );
      }
      dispatch(setToken(response.data.token));
      dispatch(setRefreshToken(response.data.refreshToken));
      dispatch(setExpire(response.data.expireAt));
      dispatch(setInsurers(response.data.insurers || []));
      dispatch(fetchUserData());
      return userType !== userTypes.BROKER && dispatch(fetchCompanyData());
    })
    .catch((error) => {
      dispatch(setLoading(false));
      message.error(error.response.data.message);
    });
};

export const fetchRefreshToken = () => (dispatch, getState) => {
  const {
    auth: { refreshToken }
  } = getState();
  return new Promise((resolve, reject) => {
    api
      .put("auth/v1/authenticate", { refreshToken })
      .then((response) => {
        dispatch(setToken(response.data.token));
        dispatch(setRefreshToken(response.data.refreshToken));
        dispatch(setExpire(response.data.expireAt));
        dispatch(setUser(response.data.user));
        resolve(response);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch(removeToken());
        } else {
          message.error(error.message);
        }
        reject(error);
      });
  });
};

export const userForgotPassword = (data) => (dispatch, getState) => {
  const { email, documentNumber } = data;
  const {
    auth: { userType }
  } = getState();
  dispatch(setLoading(true));
  dispatch(setSuccess(false));
  api
    .post(`auth/v1/authenticate/${documentNumber}/${email}/forgot-password`, {
      userType,
      documentNumber,
      email
    })
    .then(() => {
      dispatch(setLoading(false));
      dispatch(setSuccess(true));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setSuccess(false));
      dispatch(setErrors(error.response.data.errors));
      if (!Array.isArray(error.response.data.errors)) {
        message.error(error.response.data.message);
      }
    });
};

export const userUpdatePassword = (data) => (dispatch, getState) => {
  const { email, values } = data;
  dispatch(setLoading(true));
  dispatch(setSuccess(false));
  const {
    auth: {
      userType,
      user: { brokerDocumentNumber }
    }
  } = getState();
  api
    .put(
      userType === userTypes.BROKER
        ? `auth/v1/broker/${brokerDocumentNumber}/${email}/update-password`
        : `auth/v1/user/${email}/update-password`,
      values
    )
    .then(() => {
      dispatch(setLoading(false));
      dispatch(setSuccess(true));
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setSuccess(false));
      message.error(i18n.t("auth.updatePassword.requestError"));
    });
};

export const fetchUserData = () => (dispatch, getState) => {
  const {
    auth: { userType, user }
  } = getState();
  dispatch(setFetching(true));
  api
    .get(
      userType === userTypes.BROKER
        ? `auth/v1/broker/${user.brokerDocumentNumber}/${user.email}`
        : `auth/v1/user/${user.email}`
    )
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(
        setUser({ documentNumber: user?.documentNumber, ...response.data })
      );
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export const saveUserData = (data) => (dispatch, getState) => {
  const {
    auth: {
      userType,
      user: { documentNumber, brokerDocumentNumber, email }
    }
  } = getState();
  dispatch(setLoading(true));
  api
    .put(
      userType === userTypes.BROKER
        ? `auth/v1/broker/${brokerDocumentNumber}/${email}`
        : `auth/v1/user/${email}`,
      data
    )
    .then((response) => {
      dispatch(setLoading(false));
      dispatch(setErrors([]));
      if (email !== response.data.email) {
        dispatch(setUser(response.data));
        dispatch(setSuccess(true));
        dispatch(removeToken());
      } else {
        dispatch(setUser({ documentNumber, ...response.data }));
        message.success(i18n.t("profile.saveUserDataSuccess"));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setErrors(error.response.data.errors));
      message.error(error.message);
    });
};

export const logout = () => (dispatch) => {
  dispatch(removeToken());
  dispatch(removeOrigin());
  message.success(i18n.t("logout.comeBackMessage"));
};

export default authSlice.reducer;
