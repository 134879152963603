import React from "react";
import PropTypes from "prop-types";
import "./styles.less";

const LabelField = ({
  children,
  label,
  vertical = false,
  labelWidth = 115,
  className,
  labelStyles,
  containerProps
}) => (
  <div
    className={`labelWrapper ${
      vertical ? "vertical-labelWrapper" : ""
    } ${className}`}
    {...containerProps}
  >
    <div
      className="labelItem"
      style={{
        width: labelWidth,
        maxWidth: labelWidth,
        minWidth: labelWidth,
        ...labelStyles
      }}
    >
      <span>{label}</span>
    </div>
    <div className="fieldWrapper">{children}</div>
  </div>
);

LabelField.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  vertical: PropTypes.bool,
  labelWidth: PropTypes.number,
  className: PropTypes.string,
  labelStyles: PropTypes.object,
  containerProps: PropTypes.object
};

export default LabelField;
