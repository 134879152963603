import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useRedirect } from "../../hooks";
// paths
import { PATH_HOME } from "../../routes/paths";
import "./styles.less";

const BrokerHome = () => {
  const { user } = useSelector((state) => state.auth);
  const { redirect } = useRedirect();
  const { t: Translation } = useTranslation();

  const redirectToDashboard = () =>
    user.insuredDocumentNumber && redirect(PATH_HOME.root);

  useEffect(() => {
    redirectToDashboard();
  }, []);
  return (
    <div className="broker-home">
      <div className="broker-card">
        <h3>{Translation("broker.selectBrokerLabel")}</h3>
        <InfoCircleOutlined className="broker-icon" />
      </div>
    </div>
  );
};

export default BrokerHome;
