import React, { useState, useEffect } from "react";
// translation hook
import { useTranslation } from "react-i18next";
// router
import { Link, useHistory } from "react-router-dom";
// antd
import { Row } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import SweetAlert from "react-bootstrap-sweetalert";
// redux
import { useDispatch, useSelector } from "react-redux";
import { userSignIn, setUserType } from "../../store/slices/auth";
import { setSuccess } from "../../store/slices/common";
// masks
import { CNPJ_MASK_STRING } from "../../util/Masks";
// components
import {
  FormWrapper,
  FormMaskedInput,
  FormInput,
  FormPassword,
  FormCheckbox,
  ActionButton,
  LabelField,
  SigninHeader,
  SigninFooter
} from "../../components";
// custom hook
import { useRedirect } from "../../hooks";
// paths
import { PATH_ROOTS, PATH_AUTH, PATH_HOME } from "../../routes/paths";
// styles
import "./styles.less";
// constants
import { userStatus, userTypes, brandTypes } from "../../util/Enums";

const SignIn = () => {
  const [initialUserData, setInitialUserData] = useState(null);
  const dispatch = useDispatch();
  const {
    common: { loading, success },
    auth: { userToken, user, userType }
  } = useSelector((state) => state);
  const { t: translation } = useTranslation();

  const { redirect, fullRoute } = useRedirect();
  const history = useHistory();

  const onSubmit = (data) => {
    const toSendData = {
      ...data,
      documentNumber: data.documentNumber.replace(/[^A-Z0-9]/gi, ""),
      password: data.password.trim()
    };
    dispatch(userSignIn(toSendData));
  };

  const onConfirm = () => {
    dispatch(setSuccess(false));
    redirect(PATH_AUTH.login);
  };

  const handleRedirect = () => {
    if (userToken) {
      if (user.status) {
        if (user.status === userStatus.NEED_UPDATE_PASSWORD) {
          return redirect(PATH_AUTH.updatePassword);
        }
        return userType === userTypes.BROKER
          ? redirect(PATH_HOME.broker)
          : redirect(PATH_HOME.root);
      }
      return userType === userTypes.BROKER
        ? redirect(PATH_HOME.broker)
        : redirect(PATH_HOME.root);
    }
    return null;
  };

  const handleToogleUser = () => {
    if (userType === userTypes.BROKER) {
      dispatch(setUserType(userTypes.CUSTOMER));
      return history.push(PATH_ROOTS[userTypes.CUSTOMER]);
    }
    dispatch(setUserType(userTypes.BROKER));
    return history.push(PATH_ROOTS[userTypes.BROKER]);
  };

  useEffect(() => {
    handleRedirect();
  });

  useEffect(() => {
    const remember = JSON.parse(localStorage.getItem("remember"));
    if (remember) {
      let userData = {};
      if (userType === userTypes.BROKER) {
        userData = JSON.parse(localStorage.getItem("broker"));
      } else if (userType === userTypes.CUSTOMER) {
        userData = JSON.parse(localStorage.getItem("customer"));
      }
      if (userData) {
        setInitialUserData({
          ...userData,
          documentNumber: userData.documentNumber,
          remember: true
        });
      }
    }
  }, []);

  return (
    <div className="gx-login-container">
      <div className="signin-user-toogle">
        <div className="user-toogle-label">
          <h3>{translation(`auth.signIn.${userType}.toogleLabel`)}</h3>
        </div>
        <h4
          className="gx-pointer"
          onClick={handleToogleUser}
          aria-hidden="true"
        >
          {translation(`auth.signIn.${userType}.toogleTitle`)} <br />
          {translation(`auth.signIn.${userType}.toogleLink`)}
        </h4>
      </div>
      <div
        className={`gx-login-content ${
          userType === userTypes.BROKER ? "withFooter" : ""
        }`}
      >
        <SigninHeader
          type={userType}
          title={translation("auth.signIn.title")}
        />
        <FormWrapper
          spinning={false}
          onSubmit={onSubmit}
          dataReference={initialUserData}
        >
          <LabelField
            label={translation("auth.signIn.documentNumberLabel")}
            vertical
            className="gx-mb-2"
          >
            <FormMaskedInput
              name="documentNumber"
              mask={CNPJ_MASK_STRING}
              placeholder={translation("auth.signIn.documentNumberPlaceholder")}
              prefix={<UserOutlined />}
              rules={[
                {
                  required: true,
                  message: `${translation(
                    "auth.signIn.documentNumberRequiredMessage"
                  )}`
                }
              ]}
            />
          </LabelField>
          <LabelField
            label={translation("auth.signIn.emailLabel")}
            vertical
            className="gx-mb-2"
          >
            <FormInput
              name="email"
              placeholder={translation("auth.signIn.emailPlaceholder")}
              prefix={<UserOutlined />}
              rules={[
                {
                  required: true,
                  message: `${translation("auth.signIn.emailRequiredMessage")}`
                }
              ]}
            />
          </LabelField>
          <LabelField label={translation("auth.signIn.passwordLabel")} vertical>
            <FormPassword
              name="password"
              placeholder={translation("auth.signIn.passwordPlaceholder")}
              prefix={<LockOutlined />}
              iconRender={(visible) =>
                visible ? (
                  <span>{translation("auth.signIn.hidePasswordLabel")}</span>
                ) : (
                  <span>{translation("auth.signIn.showPasswordLabel")}</span>
                )
              }
              rules={[
                {
                  required: true,
                  message: `${translation(
                    "auth.signIn.passwordRequiredMessage"
                  )}`
                }
              ]}
            />
          </LabelField>
          <FormCheckbox
            label={translation("auth.signIn.rememberMeLabel")}
            name="remember"
            valuePropName="checked"
          />
          <Row justify="center" align="middle" className="gx-mb-3">
            <Link to={fullRoute(PATH_AUTH.forgotPassword)}>
              <span className="gx-signup-form-forgot gx-link">
                {translation("auth.signIn.forgotPasswordLabel")}
              </span>
            </Link>
          </Row>
          <Row justify="center" align="middle" className="gx-mt-1">
            <ActionButton
              type="primary"
              loading={loading}
              className="gx-mb-0"
              htmlType="submit"
            >
              {translation("auth.signIn.signInButtonLabel")}
            </ActionButton>
          </Row>
        </FormWrapper>
        {userType === userTypes.BROKER &&
        process.env.REACT_APP_BRAND !== brandTypes.AVERBAMAIS ? (
          <SigninFooter />
        ) : null}
        <SweetAlert
          show={success}
          success
          title={translation("profile.sweetAlertSuccessTitle")}
          onConfirm={onConfirm}
          confirmBtnText={translation("profile.sweetAlertSuccessButton")}
        >
          {translation("profile.sweetAlertSuccessMessage")}
        </SweetAlert>
      </div>
    </div>
  );
};

export default SignIn;
