import React from "react";
// logo
import registerLogo from "../../assets/images/averbamais_colorido.png";
// custom styles
import "./styles.less";

const SigninFooter = ({ className }) => {
  return (
    <div className={`signin-footer ${className}`}>
      <img className="signin-footer-logo" src={registerLogo} alt="logo" />
    </div>
  );
};

export default SigninFooter;
