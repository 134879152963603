import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { api } from "../../../../util/Api";
import { setLoading, setFetching } from "../../common";
import i18n from "../../../../translation/i18n";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    companyData: JSON.parse(localStorage.getItem("company")) || {}
  },
  reducers: {
    setCompanyData: (state, action) => {
      state.companyData = {
        ...state.companyData,
        ...action.payload
      };
      localStorage.setItem("company", JSON.stringify(action.payload));
    }
  }
});

export const { setCompanyData } = companySlice.actions;

export const fetchCompanyData = () => (dispatch) => {
  dispatch(setFetching(true));
  api
    .get("averbamais/v1/company")
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(setCompanyData(response.data));
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export const saveCompanyData = (data) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setCompanyData(data));
  api
    .put("averbamais/v1/company", data)
    .then((response) => {
      dispatch(setLoading(false));
      message.success(i18n.t("company.saveSuccessMessage"));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      message.error(error.message);
    });
};

export default companySlice.reducer;
