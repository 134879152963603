import React, { useEffect } from "react";
// prop types
import PropTypes from "prop-types";
// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// util
import { typeOptions, insurerOptions } from "../../../util/Options";
import { clearError } from "../../../util/Errors";
import {
  fetchPolicyItem,
  setErrors,
  registerPolicyItem,
  blockPolicyPayment,
  unblockPolicyPayment,
  savePolicyItem
} from "../../../store/slices/sections/policy";
// components
import { PageHeader } from "../../../components";
import EditPolicyView from "./EditPolicyView";
import StaticPolicyView from "./StaticPolicyView";

const PolicyItem = ({ title, previous }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    policy: { policyItem },
    auth: { userType }
  } = useSelector((state) => state);
  const {
    policy: { errors }
  } = useSelector((state) => state);

  const handleSubmit = () => {
    const policyPayload = {
      id: policyItem.id,
      data: {
        type: policyItem.type,
        insurer: policyItem.insurer,
        startAt: policyItem.startAt,
        endAt: policyItem.endAt,
        maximumGuaranteeLimit: policyItem.maximumGuaranteeLimit,
        policyNumber: policyItem.policyNumber,
        premium: policyItem.premium || null,
        rateDiscount: policyItem.rateDiscount || null,
        tax: null
      }
    };

    dispatch(savePolicyItem(policyPayload));
  };

  const formatValues = (event) => {
    const { name, value } = event;
    return {
      ...policyItem,
      [name]: value
    };
  };

  const handleChange = (event) => {
    dispatch(setErrors(clearError({ field: event?.name }, errors)));
    dispatch(registerPolicyItem(formatValues(event)));
  };

  const setInitialData = (data) => ({
    ...data,
    insurer: data?.insurer ? data.insurer.id : null
  });

  const renderSelectedTypeOption = () => {
    const filteredItem = typeOptions.filter(
      (item) => item.value === policyItem.type
    );
    return filteredItem[0]?.label;
  };

  const renderSelectedInsurer = () => {
    const filteredItem = insurerOptions.filter(
      (item) => item.value === policyItem?.insurer?.id
    );
    return filteredItem[0]?.label;
  };

  const handleBlockPolicy = () => {
    dispatch(blockPolicyPayment(id)).then(() => {
      dispatch(fetchPolicyItem(id));
    });
  };

  const handleUnblockPolicy = () => {
    dispatch(unblockPolicyPayment(id)).then(() => {
      dispatch(fetchPolicyItem(id));
    });
  };

  const policyViewConfig = {
    BROKER: (
      <EditPolicyView
        data={policyItem}
        onBlock={handleBlockPolicy}
        onUnblock={handleUnblockPolicy}
        onSubmit={handleSubmit}
        onChange={handleChange}
        errors={errors}
      />
    ),
    DEFAULT_STATIC: (
      <StaticPolicyView
        data={policyItem}
        renderSelectedTypeOption={renderSelectedTypeOption}
        renderSelectedInsurer={renderSelectedInsurer}
        dataReference={setInitialData(policyItem)}
      />
    )
  };

  useEffect(() => {
    dispatch(fetchPolicyItem(id));
  }, [dispatch, id]);

  return (
    <>
      <PageHeader title={title} previous={previous} />
      {policyViewConfig[userType] || policyViewConfig.DEFAULT_STATIC}
    </>
  );
};

PolicyItem.propTypes = {
  title: PropTypes.string,
  previous: PropTypes.any
};

export default PolicyItem;
