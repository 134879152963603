import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { api } from "../../../../util/Api";
import i18n from "../../../../translation/i18n";
import { setFetching, setLoading } from "../../common";

export const policySlice = createSlice({
  name: "policy",
  initialState: {
    policyList: {
      data: []
    },
    policyItem: {},
    isFetchingPolicy: false,
    errors: []
  },
  reducers: {
    registerPolicyList: (state, action) => {
      state.policyList = action.payload;
    },
    registerPolicyItem: (state, action) => {
      state.policyItem = action.payload;
    },
    setFinalTerm: (state, action) => {
      state.policyItem.finalTermAt = action.payload;
    },
    setIsFetchingPolicy: (state, action) => {
      state.isFetchingPolicy = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload || [];
    }
  }
});

export const {
  registerPolicyList,
  registerPolicyItem,
  setIsFetchingPolicy,
  setFinalTerm,
  setErrors
} = policySlice.actions;

export const fetchPolicyList = () => (dispatch) => {
  dispatch(setFetching(true));
  api
    .get("averbamais/v1/policy")
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(registerPolicyList(response.data));
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export const fetchPolicyItem = (id) => (dispatch) => {
  dispatch(setFetching(true));
  api
    .get(`averbamais/v1/policy/${id}`)
    .then((response) => {
      dispatch(setFetching(false));
      dispatch(registerPolicyItem(response.data));
      dispatch(setFinalTerm(response.data.endAt));
    })
    .catch((error) => {
      dispatch(setFetching(false));
      message.error(error.message);
    });
};

export const savePolicyItem = (actionData) => (dispatch) => {
  dispatch(setLoading(true));
  api
    .put(`averbamais/v1/policy/${actionData.id}`, actionData.data)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch(registerPolicyItem(response.data));
      dispatch(setFinalTerm(response.data.endAt));
      message.success(i18n.t("policy.successMessage"));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setErrors(error.response.data?.errors));
      message.error(error.response.data.error);
    });
};

export const blockPolicyPayment = (id) => (dispatch) => {
  dispatch(setFetching(true));
  return new Promise((resolve, reject) => {
    api
      .put(`averbamais/v1/policy/${id}/blocking-payment`, {})
      .then((response) => {
        message.success(i18n.t("policy.blockPolicyPaymentSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.error);
        reject(error);
      })
      .finally(() => {
        dispatch(setFetching(false));
      });
  });
};

export const unblockPolicyPayment = (id) => (dispatch) => {
  dispatch(setFetching(true));
  return new Promise((resolve, reject) => {
    api
      .delete(`averbamais/v1/policy/${id}/blocking-payment`)
      .then((response) => {
        message.success(i18n.t("policy.unblockPolicyPaymentSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response.data.error);
        reject(error);
      })
      .finally(() => {
        dispatch(setFetching(false));
      });
  });
};

export default policySlice.reducer;
