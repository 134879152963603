import React from "react";
// router
import { BrowserRouter as Router } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// routes
import renderRoutes, { routes } from "./routes/routes";
// styles
import "./assets/vendors/style";
import "./theme/wieldy.less";
import "./styles/styles.less";

const App = () => {
  const { userType } = useSelector((state) => state.auth);

  return <Router>{renderRoutes(routes, userType)}</Router>;
};

export default App;
