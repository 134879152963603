/* eslint-disable no-restricted-syntax */
import React, { useEffect } from "react";
// prop types
import PropTypes from "prop-types";
// redux
import { useDispatch } from "react-redux";
import { appendParamsData } from "../../store/slices/routesParams";

const GetRouteParams = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    for (let pair of searchParams.entries()) {
      dispatch(appendParamsData({ [pair[0]]: pair[1] }));
    }
  });
  return <>{children}</>;
};

GetRouteParams.propTypes = {
  children: PropTypes.node
};

export default GetRouteParams;
