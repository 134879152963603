import React from "react";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
// styles
import "./styles.less";

const CheckIndicator = ({ label, value, className }) => {
  return (
    <div
      className={`check-indicator ${
        value ? "success-indicator" : "error-indicator"
      } ${className}`}
    >
      {value ? <CheckOutlined /> : <CloseOutlined />}
      <span className="gx-px-1 indicator-label">{label}</span>
    </div>
  );
};

export default CheckIndicator;
