import React, { useState } from "react";
// prop types
import PropTypes from "prop-types";
// antd
import { Modal, Button, Input, Row, Form } from "antd";
// styles
import "./styles.less";

const { TextArea } = Input;

const CalcelReasonModal = ({
  loading,
  visible,
  buttonText,
  title,
  help,
  onCancel,
  onSubmit,
  onChange
}) => {
  const [reason, setReason] = useState("");

  const handleChange = (e) => {
    setReason(e.target.value);
    return onChange && onChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return onSubmit && onSubmit(reason);
  };

  return (
    <Modal
      closable
      maskClosable
      visible={visible}
      icon={null}
      footer={null}
      onCancel={onCancel}
      className="cancelReasonModal"
    >
      <h4>{title}</h4>
      <Form.Item
        className="gx-mb-30"
        validateStatus={help && "error"}
        help={help}
      >
        <TextArea autoSize={{ minRows: 1 }} onChange={handleChange} />
      </Form.Item>
      <Row justify="center">
        <Button
          loading={loading}
          type="primary"
          className="gx-m-0"
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
      </Row>
    </Modal>
  );
};

CalcelReasonModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  help: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};

export default CalcelReasonModal;
