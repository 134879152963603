import React from "react";
// ant components
import { Spin } from "antd";
// custom styles
import "./styles.less";

const PageLayoutCard = ({
  children,
  spinning = false,
  title,
  previous,
  pageTag
}) => {
  return (
    <Spin spinning={spinning}>
      <div className="pageLayout">
        <div className="pageLayoutCard">
          {React.cloneElement(children, {
            title,
            previous,
            pageTag
          })}
        </div>
      </div>
    </Spin>
  );
};

export default PageLayoutCard;
