import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { api } from "../../../util/Api";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    error: "",
    loading: false,
    message: "",
    navCollapsed: true,
    width: window.innerWidth,
    success: false,
    fetching: false,
    pageTag: null,
    companiesList: [],
    currentMenuKeys: [],
    // stepper
    currentStep: 0
  },
  reducers: {
    updateWindowWidth: (state, action) => {
      state.width = action.payload;
    },
    toggleCollapsedSideNav: (state, action) => {
      state.navCollapsed = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setFetching: (state, action) => {
      state.fetching = action.payload;
    },
    setPageTag: (state, action) => {
      state.pageTag = action.payload;
    },
    setCompaniesList: (state, action) => {
      state.companiesList = action.payload;
    },
    setCurrentMenuKeys: (state, action) => {
      state.currentMenuKeys = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    }
  }
});

export const fetchCompaniesList = (filters) => (dispatch) => {
  dispatch(setFetching(true));
  const queryString = new URLSearchParams({ ...filters }).toString();
  const brokerData = JSON.parse(localStorage.getItem("broker"));
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${window._env_.REACT_APP_API_GATEWAY_URL}/averbamais/v1/broker/companies?${queryString}`,
        {
          headers: { Authorization: `Bearer ${brokerData.token}` }
        }
      )
      .then((response) => {
        dispatch(setFetching(false));
        dispatch(setCompaniesList(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setFetching(false));
        message.error(error.response.data.message);
        reject(error);
      });
  });
};

export const fetchLocation = (city) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .get(`averbamais-engine/v1/locations/${city}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const {
  updateWindowWidth,
  toggleCollapsedSideNav,
  setLoading,
  setSuccess,
  setFetching,
  setPageTag,
  setCompaniesList,
  setCurrentMenuKeys,
  // stepper
  setCurrentStep
} = commonSlice.actions;

export default commonSlice.reducer;
