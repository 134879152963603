import React from "react";
// redux
import { useSelector } from "react-redux";
// components
import RegisterSection from "./RegisterSection";
import { Stepper, PageHeader } from "../../components";

const Register = ({ children, title, previous, pageTag }) => {
  const { currentStep, steps } = useSelector((state) => state.register);
  return (
    <>
      <PageHeader title={title} previous={previous} pageTag={pageTag} />
      <Stepper steps={steps} current={currentStep} />
      {children || <RegisterSection />}
    </>
  );
};

export default Register;
