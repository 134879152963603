import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// prop types
import PropTypes from "prop-types";
// antd
import { Button, Tooltip, Modal } from "antd";
// antd icons
import { EditOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import {
  fetchPolicyList,
  blockPolicyPayment,
  unblockPolicyPayment
} from "../../../store/slices/sections/policy";
import { InputCurrency, NewTable, PageHeader } from "../../../components";
import { formatDateToDisplay } from "../../../util/formatters";
import "./styles.less";
// custom hooks
import { useRedirect } from "../../../hooks";
// paths
import { PATH_POLICY } from "../../../routes/paths";

const Policies = ({ title, previous }) => {
  const { t: translation } = useTranslation();
  const { redirect } = useRedirect();
  const {
    policy: { policyList },
    auth: { userType }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleBlockPolicy = (id) => {
    dispatch(blockPolicyPayment(id)).then(() => {
      dispatch(fetchPolicyList());
    });
  };

  const handleUnblockPolicy = (id) => {
    dispatch(unblockPolicyPayment(id)).then(() => {
      dispatch(fetchPolicyList());
    });
  };

  const handleBlockAction = (id, lockedAt) => {
    const { confirm } = Modal;
    const confirmLogoutModal = () => {
      confirm({
        title: lockedAt
          ? translation("policy.list.modal.unblockPolicyTitle")
          : translation("policy.list.modal.blockPolicyTitle"),
        onOk() {
          if (lockedAt) {
            handleUnblockPolicy(id);
          } else {
            handleBlockPolicy(id);
          }
        },
        onCancel() {
          return false;
        },
        okText: translation("policy.list.modal.confirmButton"),
        cancelText: translation("policy.list.modal.cancelButton")
      });
    };
    confirmLogoutModal();
  };

  const columns = [
    {
      title: translation("policies.tableColumnType"),
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (text) => (
        <span className="policyTableText">
          {translation(`constants.${text}`)}
        </span>
      )
    },
    {
      title: translation("policies.tableColumnPolicyNumber"),
      dataIndex: "policyNumber",
      key: "policyNumber",
      responsive: ["md"],
      align: "center",
      render: (text) => <span className="policyTableText">{text}</span>
    },
    {
      title: translation("policies.tableColumnMaximumLimit"),
      dataIndex: "maximumGuaranteeLimit",
      key: "maximumGuaranteeLimit",

      align: "center",
      render: (text) => (
        <span className="policyTableText">
          <InputCurrency value={text} displayType="text" />
        </span>
      )
    },
    {
      title: translation("policies.tableColumnValidity"),
      dataIndex: "validity",
      key: "validity",
      align: "center",
      render: (text) => (
        <span className="policyTableText startEndColumn">
          <span>{formatDateToDisplay(text.startAt)}</span>
          <span className="gx-mx-1">a</span>
          <span>{formatDateToDisplay(text.endAt)}</span>
        </span>
      )
    },
    {
      title: translation("policies.tableColumnAction"),
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, { id, lockedAt }) => (
        <div className="gx-d-flex gx-justify-content-center">
          <Tooltip
            title={translation("policy.list.editTooltip")}
            placement="top"
          >
            <Button
              type="text"
              className="gx-mr-1"
              size="small"
              icon={<EditOutlined />}
              style={{
                maxHeight: "20px",
                marginBottom: "0px"
              }}
              onClick={() => redirect(`${PATH_POLICY.root}/${id}`)}
            />
          </Tooltip>
          {userType === "BROKER" && (
            <Tooltip
              title={
                lockedAt
                  ? translation("policy.list.unblockTooltip")
                  : translation("policy.list.blockTooltip")
              }
              placement="top"
            >
              <Button
                type="text"
                size="small"
                icon={
                  lockedAt ? (
                    <UnlockOutlined style={{ color: "green" }} />
                  ) : (
                    <LockOutlined style={{ color: "red" }} />
                  )
                }
                style={{
                  maxHeight: "20px",
                  marginBottom: "0px"
                }}
                onClick={() => handleBlockAction(id, lockedAt)}
              />
            </Tooltip>
          )}
        </div>
      )
    }
  ];

  const proccessTableData = (list) => ({
    ...list,
    data: list.data.map((item) => {
      if (item.startAt && item.endAt) {
        return {
          ...item,
          validity: { startAt: item.startAt, endAt: item.endAt }
        };
      }
      return item;
    })
  });

  useEffect(() => {
    dispatch(fetchPolicyList());
  }, [dispatch]);

  return (
    <>
      <PageHeader title={title} previous={previous} />
      <NewTable
        requestData={proccessTableData(policyList)}
        columns={columns}
        requestAction={fetchPolicyList}
        size="small"
      />
    </>
  );
};

Policies.propTypes = {
  title: PropTypes.string,
  previous: PropTypes.string
};

export default Policies;
