import React from "react";
// components
import { Row, Col, Input } from "antd";
import FormList from "antd/lib/form/FormList";
import FormItem from "antd/lib/form/FormItem";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import MaskedInput from "react-text-mask";
import FormMaskedInput from "../FormMaskedInput";

import "./style.less";

const FieldList = (props) => {
  const { initialValue, name, placeholder, mask } = props;

  return (
    <FormList initialValue={initialValue} name={name}>
      {(fields, { add, remove }) => (
        <Row type="flex" align="bottom">
          <Col flex="20">
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="form-field-list" key={key}>
                <FormItem
                  {...restField}
                  name={name}
                  fieldKey={fieldKey}
                  className="gx-m-0 gx-mb-2 field-list-item"
                >
                  {mask ? (
                    <FormMaskedInput
                      type="text"
                      placeholder={placeholder}
                      className="ant-input input-lg"
                      mask={mask}
                    />
                  ) : (
                    <Input className="gx-input" placeholder={placeholder} />
                  )}
                </FormItem>
                {fields.length > 1 ? (
                  <DeleteOutlined
                    className="gx-size-30"
                    style={{ color: "red" }}
                    onClick={() => remove(name)}
                  />
                ) : null}
              </div>
            ))}
          </Col>
          <Col flex="auto" className="gx-pb-1">
            <PlusCircleOutlined
              className="gx-size-30"
              style={{ color: "green" }}
              onClick={() => add()}
            />
          </Col>
        </Row>
      )}
    </FormList>
  );
};

FieldList.defaultProps = {
  name: "field-list",
  initialValue: [""]
};

export default FieldList;
