import React, { useState, useEffect } from "react";
// translation hook
import { useTranslation } from "react-i18next";
// components
import { CheckIndicator } from "../../components";
// styles
import "./styles.less";
// contatnts
import { passwordStrengthOptions } from "../../util/Options";

const usePasswordChecker = () => {
  const [isOk, setIsOk] = useState(false);
  const [password, setPassword] = useState("");
  const [options, setOptions] = useState(passwordStrengthOptions);

  const { t: translation } = useTranslation();

  useEffect(() => {
    const checkPassword = () => {
      let okValue = true;
      const newValues = [];
      passwordStrengthOptions.forEach((item) => {
        item.value = password ? item.regex.test(password) : false;
        newValues.push(item);
        okValue = okValue && item.value;
      });
      setOptions(newValues);
      setIsOk(okValue);
    };
    checkPassword();
  }, [password]);

  const StrengthIndicator = () => (
    <>
      <span className="passwordCheckerTitle">
        {translation("auth.updatePassword.passwordStrengthIndicatorTitle")}:
      </span>
      {options.map((item, index) => (
        <CheckIndicator key={index} label={item.label} value={item.value} />
      ))}
    </>
  );

  return [isOk, StrengthIndicator, setPassword];
};

export default usePasswordChecker;
