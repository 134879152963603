import React, { useState } from "react";
// router
import { Link } from "react-router-dom";
// antd
import { Row, Col, Upload, message, Spin, Radio } from "antd";
import { InboxOutlined } from "@ant-design/icons";
// translations
import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../store/slices/common";
import {
  setRegisterData,
  setChangedRegisterData
} from "../../../store/slices/sections/register";
// hooks
import { useRedirect } from "../../../hooks";
// paths
import { PATH_REPORT, PATH_CANCEL } from "../../../routes/paths";
// util
import { uploadConfig } from "../../../util/Upload";
import { documentTypes } from "../../../util/Options";
// styles
import "./styles.less";

const { Dragger } = Upload;

const CancelByXML = () => {
  const {
    common: { loading },
    register: { registerData }
  } = useSelector((state) => state);

  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const { fullRoute, redirect } = useRedirect();

  const handleProgress = () => {
    message.info(translation("cancel.uploadXml.progressMessage"));
  };

  const handleSuccessUpload = (res) => {
    const { id } = res;
    message.success(translation("cancel.uploadXml.successMessage"));
    dispatch(setRegisterData(res));
    dispatch(setLoading(false));
    redirect(`${PATH_CANCEL.root}/${id}`);
  };

  const handleErrorUpload = (error) => {
    message.error(
      error.response.data.message ||
        translation("cancel.uploadXml.errorMessage")
    );
    dispatch(setLoading(false));
  };

  const handleStartUpload = () => {
    dispatch(setLoading(true));
  };

  const handleChangeDocumentType = (e) => {
    dispatch(setChangedRegisterData({ documentType: e.target.value }));
  };

  return (
    <Spin spinning={loading}>
      <Row className="gx-mb-20">
        <h4>{translation("cancel.cancelWithXml.subtitle")}</h4>
      </Row>
      <Row className="gx-mb-20">
        <Col sm={24} md={4} className="gx-p-0">
          <h4>{translation("cancel.cancelWithXml.documentTypeLabel")}</h4>
        </Col>
        <Col sm={24} md={8}>
          <Radio.Group
            options={documentTypes}
            value={registerData.documentType}
            onChange={handleChangeDocumentType}
          />
        </Col>
      </Row>
      <Row className="uploadContainer">
        <Col sm={24} md={24} className="gx-p-0">
          <Dragger
            action={`/averbamais-engine/v1/register/${registerData.documentType}/cancel`}
            {...uploadConfig("POST", null, handleProgress)}
            showUploadList={false}
            onSuccess={handleSuccessUpload}
            onError={handleErrorUpload}
            onStart={handleStartUpload}
          >
            <h5 className="ant-upload-text">
              {translation("cancel.cancelWithXml.draggerText")}
            </h5>
            <InboxOutlined className="ant-upload-drag-icon" />
          </Dragger>
        </Col>
      </Row>
      {/* <Row>
        <h5>
          {translation("cancel.cancelWithXml.label.startText")}&nbsp;
          <Link className="linkLabel" to={fullRoute(PATH_REPORT.root)}>
            {translation("cancel.cancelWithXml.label.linkText")}&nbsp;
          </Link>
          {translation("cancel.cancelWithXml.label.endText")}
        </h5>
      </Row> */}
    </Spin>
  );
};

export default CancelByXML;
