import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import settings from "./slices/settings";
import common from "./slices/common";
import policy from "./slices/sections/policy";
import company from "./slices/sections/company";
import register from "./slices/sections/register";
import users from "./slices/sections/users";
import dashboard from "./slices/sections/dashboard";
import routesParams from "./slices/routesParams";

export default configureStore({
  reducer: {
    auth,
    routesParams,
    settings,
    common,
    policy,
    company,
    register,
    users,
    dashboard
  }
});
