import moment from "moment";
import { ISODATE, DATEMASK, DATEHOURMASK, ISODATETIME } from "./Masks";

export const formatDateToDisplay = (date) =>
  date ? moment(date).format(DATEMASK) : null;

export const formatDateToDisplayWithHour = (date) =>
  date ? moment(date).format(DATEHOURMASK) : null;

export const formatToDate = (date) =>
  date ? moment(date).format(ISODATE) : null;

export const formatToDateTime = (date) =>
  date ? moment(date).format(ISODATETIME) : null;

export const removeMask = (data) =>
  data ? data.replace(/[^A-Z0-9]/gi, "") : null;

export const isMoment = (data) => moment.isMoment(data);

export const formatProtocolType = (type) => type.replace("_", "-");
