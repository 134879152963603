import React, { useEffect } from "react";
// translation
import { useTranslation } from "react-i18next";
// router
import { useParams } from "react-router-dom";
// antd
import { Row, Upload, Spin, message } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchRegisterById } from "../../../store/slices/sections/register";
import { setLoading } from "../../../store/slices/common";
// components
import { ReportViewer, ActionButton, PageHeader } from "../../../components";
// upload
import { uploadConfig } from "../../../util/Upload";
// custom hook
import { useRedirect } from "../../../hooks";
// custom hook
import { PATH_HOME } from "../../../routes/paths";

const RegisterCancel = ({ title }) => {
  const {
    register: { registerData },
    common: { loading }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { id } = useParams();
  const { t: translation } = useTranslation();
  const { redirect, fullRoute } = useRedirect();

  const url = `/averbamais-engine/v1/register/${id}/${registerData.documentType}/cancel`;
  const requesType = "PUT";

  const handleSuccessUpload = (res) => {
    message.success(translation("cancel.upload.successMessage"));
    redirect(PATH_HOME.root);
  };

  const handleErrorUpload = (error) => {
    message.error(
      error.response.data.message || translation("cancel.upload.errorMessage")
    );
    dispatch(setLoading(false));
  };

  const handleProgress = () => {
    message.info(translation("cancel.upload.progressMessage"));
  };

  const handleStartUpload = () => {
    dispatch(setLoading(true));
  };

  useEffect(() => {
    dispatch(fetchRegisterById(id));
  }, [dispatch]);

  return (
    <>
      <PageHeader title={title} />
      <ReportViewer reportData={registerData} />
      <Row justify="center" align="middle" className="gx-mt-3">
        <span>{translation("cancel.attachDocumentLabel")}</span>
      </Row>
      <Row justify="center" align="middle" className="gx-mt-3 gx-pb-3">
        <Spin spinning={loading}>
          <Upload
            action={url}
            {...uploadConfig(requesType, null, handleProgress)}
            showUploadList={false}
            onSuccess={handleSuccessUpload}
            onError={handleErrorUpload}
            onStart={handleStartUpload}
          >
            <ActionButton type="primary" size="large" className="gx-m-0">
              {translation("cancel.attachDocumentButton")}
            </ActionButton>
          </Upload>
        </Spin>
      </Row>
    </>
  );
};

export default RegisterCancel;
