import { api } from "./Api";

export const uploadConfig = (
  requesType,
  handleFileChange,
  handleProgress,
  extraParam
) => {
  const setExtras = (formData) => {
    const json = JSON.stringify(extraParam);
    const blob = new Blob([json], {
      type: "application/json"
    });
    return formData.append("request", blob);
  };
  return {
    name: "file",
    multiple: true,
    headers: {
      "Content-type": "multipart/form-data"
    },
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: false
    },
    customRequest: (option) => {
      const { action, file, headers, onSuccess, onError, onProgress } = option;
      const formData = new FormData();
      formData.append("file", file);
      if (extraParam) {
        setExtras(formData);
      }
      const config = {
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            file
          );
        },
        headers
      };
      if (requesType === "PUT") {
        api
          .put(action, formData, config)
          .then(({ data: response }) => {
            return onSuccess(response, file);
          })
          .catch((err) => {
            return onError(err);
          });
      } else if (requesType === "POST") {
        api
          .post(action, formData, config)
          .then(({ data: response }) => {
            return onSuccess(response, file);
          })
          .catch((err) => {
            return onError(err);
          });
      }
    },
    onChange: handleFileChange,
    onProgress: ({ percent }, file) => {
      return handleProgress && handleProgress(percent, file);
    }
  };
};
