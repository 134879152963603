import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row, Table, Spin } from "antd";
import { PieChart, Pie, BarChart, Bar, Legend, Cell, Tooltip } from "recharts";
import { PATH_REGISTER, PATH_CANCEL, PATH_REPORT } from "../../routes/paths";
import { setAllDashboard } from "../../store/slices/sections/dashboard";
import { fetchCompanyData } from "../../store/slices/sections/company";
import { BorderCard, ActionButton, InputCurrency } from "../../components";
import "./styles.less";
import { useRedirect } from "../../hooks";
import { formatDateToDisplay } from "../../util/formatters";
// enums
import { registerStatusEnum } from "../../util/Enums";
import { registerStatus } from "../../util/Options";

const Dashboard = () => {
  const { redirect } = useRedirect();
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const {
    dashboard: {
      goodsAmountTotal,
      pendentRegisterTotal,
      goodsMerchandiseTotal,
      registerAmountByDestination,
      todayRegisterList,
      boardingTotal,
      isFetchingAll
    },
    auth: { user }
  } = useSelector((state) => state);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const getStatusLabel = (status) => {
    const selectedStatus = registerStatus.find((item) => item.value === status);
    return selectedStatus ? selectedStatus.label : "";
  };

  const handleStatusAction = (record) => {
    const { id, status } = record;
    if (status === registerStatusEnum.registered) {
      return redirect(`${PATH_REPORT.root}/${id}`);
    }

    if (status === registerStatusEnum.pending) {
      return redirect(`${PATH_REPORT.root}/confirm/${id}`);
    }

    if (status === registerStatusEnum.cancelled) {
      return redirect(`${PATH_REPORT.root}/${id}`);
    }

    if (status === registerStatusEnum.cancel_in_analysis) {
      return redirect(`${PATH_CANCEL.root}/outros/${id}`);
    }

    return null;
  };

  const customLegend = ({ payload }, name) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {payload.map((item, index) => (
        <span
          key={index}
          style={{
            color: COLORS[index % COLORS.length]
          }}
        >
          {item[name]}
        </span>
      ))}
    </div>
  );

  const customLegendMonth = ({ payload }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {payload.map((item, index) => (
        <span
          key={index}
          style={{
            color: COLORS[index % COLORS.length]
          }}
        >
          {translation(`dashboard.month${item.month}`)}
        </span>
      ))}
    </div>
  );

  const columns = [
    {
      title: translation("dashboard.protocolNumberColumnLabel"),
      dataIndex: "protocol",
      key: "protocol",
      align: "center"
    },
    {
      title: translation("dashboard.originAndDestinationColumnLabel"),
      dataIndex: "originAndDestination",
      key: "originAndDestination",
      align: "center"
    },
    {
      title: translation("dashboard.boardingAtColumnLabel"),
      dataIndex: "boardingAt",
      key: "boardingAt",
      align: "center",
      render: (text) => formatDateToDisplay(text)
    },
    {
      title: translation("dashboard.merchandiseValueColumnLabel"),
      dataIndex: "merchandiseValue",
      key: "merchandiseValue",
      align: "center",
      render: (text) => <InputCurrency value={text} displayType="text" />
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => <span>{getStatusLabel(status)}</span>
    }
  ];

  const mergeOriginAndDestinationToColumn = (list) =>
    list.map((item, index) => ({
      ...item,
      key: index,
      originAndDestination: `${item.origin} - ${item.destination}`,
      status: item.status
    }));

  const customTooltipMonth = ({ payload }) =>
    payload.map((item, index) => (
      <span key={index} className="recharts-default-tooltip">
        <span>{translation(`dashboard.month${item.payload.month}`)}</span>
        <span> : </span>
        <InputCurrency
          value={item.payload.amount}
          displayType="text"
          style={{ color: item.payload.fill }}
        />
      </span>
    ));

  const customTooltipState = ({ payload }) =>
    payload.map((item, index) => (
      <span
        key={index}
        className="recharts-default-tooltip"
        style={{ color: item.payload.fill }}
      >
        {`${item.payload.state} : ${item.payload.amount}`}
      </span>
    ));

  useEffect(() => {
    dispatch(setAllDashboard());
    dispatch(fetchCompanyData());
  }, [dispatch, user.insuredDocumentNumber, user.documentNumber]);

  return (
    <Spin spinning={isFetchingAll}>
      <Row justify="center">
        <Col md={12}>
          <Row justify="center" className="gx-mt-2 gx-h-100">
            <BorderCard>
              <Row justify="center" className="gx-mt-1 dashboard-card-label">
                {translation("dashboard.goodsAmountCardLabel")}
              </Row>
              <Row
                justify="center"
                align="middle"
                className="dashboard-card-content"
              >
                <div className="dashboard-values">
                  <span>{boardingTotal}</span>
                  <InputCurrency value={goodsAmountTotal} displayType="text" />
                </div>
              </Row>
            </BorderCard>
          </Row>
        </Col>
        <Col md={12}>
          <Row justify="center" className="gx-mt-2 gx-h-100">
            <BorderCard>
              <Row justify="center" className="gx-mt-2 dashboard-card-label">
                {translation("dashboard.pendentRegisterTotalCardLabel")}
              </Row>
              <Row
                justify="center"
                align="middle"
                className="dashboard-card-content"
              >
                <div className="dashboard-values">
                  <span>{pendentRegisterTotal}</span>
                </div>
              </Row>
            </BorderCard>
          </Row>
        </Col>
      </Row>
      {goodsMerchandiseTotal.length &&
      registerAmountByDestination.length &&
      !isFetchingAll ? (
        <Row align="middle" justify="center">
          <Col md={12} sm={24}>
            <Row justify="center" className="gx-mt-2 dashboard-card-label">
              <span className="chart-title">
                {translation("dashboard.stateChartTitle")}
              </span>
            </Row>
            <Row justify="center">
              <PieChart width={300} height={250} className="gx-pt-4">
                <Legend
                  payload={registerAmountByDestination}
                  align="right"
                  layout="vertical"
                  content={(payload) => customLegend(payload, "state")}
                />
                <Tooltip
                  position={{ x: 10, y: 10 }}
                  content={customTooltipState}
                />
                <Pie
                  data={registerAmountByDestination}
                  dataKey="amount"
                  nameKey="state"
                  outerRadius={85}
                >
                  {registerAmountByDestination.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </Row>
          </Col>
          <Col md={12} sm={24}>
            <Row justify="center" className="gx-mt-2 dashboard-card-label">
              <span className="chart-title">
                {translation("dashboard.monthChartTitle")}
              </span>
            </Row>
            <Row justify="center" align="bottom">
              <BarChart
                width={300}
                height={250}
                data={goodsMerchandiseTotal}
                barSize={65}
              >
                <Legend
                  payload={goodsMerchandiseTotal}
                  align="right"
                  layout="vertical"
                  content={customLegendMonth}
                />
                <Tooltip
                  content={customTooltipMonth}
                  payload={goodsMerchandiseTotal}
                  position={{ x: 50, y: 25 }}
                />
                <Bar dataKey="amount" fill="#8884d8">
                  {goodsMerchandiseTotal.map((entry, index) => (
                    <Cell
                      key={`bar-cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      name={entry.month}
                    />
                  ))}
                </Bar>
              </BarChart>
            </Row>
          </Col>
        </Row>
      ) : (
        !isFetchingAll && (
          <Row justify="center" align="middle">
            <Col md={24}>
              <p className="no-register-label">
                {translation("dashboard.noRegisterText")}
              </p>
            </Col>
          </Row>
        )
      )}
      <Row>
        <Col md={24} className="gx-my-4">
          <Row justify="center">
            <ActionButton
              type="primary"
              size="large"
              onClick={() => redirect(PATH_REGISTER.root)}
            >
              {translation("dashboard.newRegisterAction")}
            </ActionButton>
          </Row>
        </Col>
      </Row>
      <Row>
        <Table
          className="gx-mt-1 gx-table-responsive gx-w-100"
          tableLayout="fixed"
          dataSource={mergeOriginAndDestinationToColumn(todayRegisterList)}
          columns={columns}
          locale={{ emptyText: translation("components.antTable.emptyData") }}
          onRow={(record) => ({
            className: "clickable-row",
            onClick: () => handleStatusAction(record)
          })}
        />
      </Row>
    </Spin>
  );
};

export default Dashboard;
