import React from "react";
import { Form, Checkbox } from "antd";
// custom styles
import "./styles.less";

const FormCheckbox = ({ label, name, valuePropName, ...restProps }) => {
  return (
    <Form.Item
      name={name}
      className="formCheckbox"
      valuePropName={valuePropName}
    >
      <Checkbox {...restProps}>{label}</Checkbox>
    </Form.Item>
  );
};

export default FormCheckbox;
