import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Space, Modal } from "antd";
import { LockOutlined, UnlockOutlined, KeyOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserList,
  blockManyUsers,
  unBlockManyUsers,
  sendPassword,
  deleteUser
} from "../../store/slices/sections/users";
import {
  NewTable,
  IconButton,
  ActionButton,
  PageHeader
} from "../../components";
import { userActions, userStatus } from "../../util/Enums";
import { formatDateToDisplayWithHour } from "../../util/formatters";
// custom hook
import { useRedirect } from "../../hooks";
// paths
import { PATH_USERS } from "../../routes/paths";

const Users = ({ title, previous }) => {
  const { confirm } = Modal;
  const { redirect } = useRedirect();
  const { t: translation } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [buttonsLoading, setButtonsLoading] = useState(false);
  const {
    users: { userList },
    common: { loading }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const haveBlockedStatus = () =>
    selectedItems.find((item) => item.status === userStatus.BLOCKED);

  const cannotUnBlock = () =>
    selectedItems.find((item) => item.status !== userStatus.BLOCKED);

  const confirmModal = (actionType, action) => {
    confirm({
      title:
        selectedItems.length > 1 && actionType !== userActions.DELETE
          ? translation(`users.${actionType}ManyConfirmTitle`)
          : translation(`users.${actionType}ConfirmTitle`),
      onOk() {
        action();
      },
      onCancel() {
        return false;
      },
      okText: translation("users.confirmAction"),
      cancelText: translation("users.cancelAction")
    });
  };

  const handleBlockUser = () => {
    setButtonsLoading({ ...buttonsLoading, block: true });
    return dispatch(blockManyUsers(selectedItems));
  };

  const handleUnblockUser = () => {
    setButtonsLoading({ ...buttonsLoading, unBlock: true });
    return dispatch(unBlockManyUsers(selectedItems));
  };

  const handleSendPassword = () => {
    setButtonsLoading({ ...buttonsLoading, sendPass: true });
    return dispatch(sendPassword(selectedItems));
  };

  const handleDeleteUser = (dataItem) =>
    dispatch(
      deleteUser({
        email: dataItem.email
      })
    );

  const redirectToCreateUser = () => {
    redirect(PATH_USERS.newUser);
  };

  const redirectToEditUser = (dataItem) => {
    redirect(`${PATH_USERS.root}/${dataItem.email}/${dataItem.documentNumber}`);
  };

  useEffect(() => !loading && setButtonsLoading(false), [loading]);

  useEffect(() => {
    const getSelectedItems = (selectedKeys) => {
      if (selectedKeys) {
        return selectedKeys.map((rowKey) => userList.data[rowKey]);
      }
    };
    setSelectedItems(getSelectedItems(selectedRowKeys));
  }, [selectedRowKeys, userList]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [userList]);

  const columns = [
    {
      title: translation("users.userListNameColumn"),
      dataIndex: "name"
    },
    {
      title: translation("users.userListEmailColumn"),
      dataIndex: "email",
      responsive: ["md"]
    },
    {
      title: translation("users.userListLastAccessAtColumn"),
      dataIndex: "lastAccessAt",
      responsive: ["md"],
      render: (text) => formatDateToDisplayWithHour(text)
    },
    {
      title: translation("users.userListStatusColumn"),
      dataIndex: "status",
      render: (text) => translation(`users.userStatus${text}`)
    },
    {
      title: translation("users.userListActionsColumn"),
      dataIndex: "actions",
      align: "center",
      render: (text, dataItem) => (
        <Space wrap>
          <IconButton
            onClick={() => redirectToEditUser(dataItem)}
            label={translation("users.userListEditActionColumn")}
            icon={<span className="icon icon-edit" />}
          />
          <IconButton
            onClick={() =>
              confirmModal(userActions.DELETE, () => handleDeleteUser(dataItem))
            }
            label={translation("users.userListDeleteActionColumn")}
            icon={<span className="icon icon-trash" />}
          />
        </Space>
      )
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (event) => setSelectedRowKeys(event)
  };
  return (
    <>
      <PageHeader title={title} previous={previous} />
      <NewTable
        headerItems={
          <Row justify="end">
            <Space className="gx-mb-2">
              <IconButton
                onClick={() =>
                  confirmModal(userActions.UNBLOCK, handleUnblockUser)
                }
                icon={<UnlockOutlined />}
                disabled={cannotUnBlock() || !selectedRowKeys.length}
                loading={buttonsLoading?.unBlock}
                label={translation("users.userListActionUnblock")}
              />

              <IconButton
                onClick={() => confirmModal(userActions.BLOCK, handleBlockUser)}
                disabled={haveBlockedStatus() || !selectedRowKeys.length}
                icon={<LockOutlined />}
                loading={buttonsLoading?.block}
                label={translation("users.userListActionBlock")}
              />
              <IconButton
                onClick={handleSendPassword}
                icon={<KeyOutlined />}
                disabled={haveBlockedStatus() || !selectedRowKeys.length}
                loading={buttonsLoading?.sendPass}
                label={translation("users.userListActionSendPassword")}
              />
            </Space>
          </Row>
        }
        rowSelection={rowSelection}
        requestData={userList}
        columns={columns}
        requestAction={fetchUserList}
        size="small"
      />
      <Row justify="center">
        <ActionButton
          type="primary"
          size="large"
          onClick={redirectToCreateUser}
        >
          {translation("users.newUserButtonLabel")}
        </ActionButton>
      </Row>
    </>
  );
};

export default Users;
